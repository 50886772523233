import type {
  ApolloCache,
  DocumentNode,
  NormalizedCacheObject,
} from '@apollo/client';

import type {
  Board,
  Card,
  CheckItem,
  Checklist,
  Label,
  List,
  Maybe,
  Member,
  Organization,
} from '../../generated';
import { firstLetterToLower } from '../../stringOperations';
import type { SupportedModelTypes, TypedPartialWithID } from '../../types';
import { muteMissingFieldErrors } from './muteMissingFieldErrors';

type Model = TypedPartialWithID<
  Board | Member | Organization | List | Card | Label | Checklist | CheckItem,
  SupportedModelTypes
>;

export class GeneralizedFilterPatcher<
  CurrentModel extends Model,
  ParentModel extends Model,
  QueryType extends { __typename: 'Query' } & {
    [K in Lowercase<ParentModel['__typename']>]?: Maybe<
      {
        __typename: ParentModel['__typename'];
        id: Pick<ParentModel, 'id'> | string;
      } & {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        [K in `${Lowercase<CurrentModel['__typename']>}s`]: Array<
          { __typename: CurrentModel['__typename'] } & Pick<CurrentModel, 'id'>
        >;
      }
    >;
  },
  RelatedId extends string,
> {
  private readonly parentModelName: Lowercase<ParentModel['__typename']>;
  private readonly modelTypeName: CurrentModel['__typename'];
  private readonly parentTypeName: ParentModel['__typename'];
  private readonly query: DocumentNode;
  private readonly filters: {
    [filter: string]: {
      dataKey: string;
      canSyncWithEmptyData?: boolean;
      addSingleRelationWhen: (
        model: CurrentModel,
        parentId: RelatedId,
        previousRelatedId?: RelatedId | null,
      ) => boolean;
      removeSingleRelationWhen: (
        model: CurrentModel,
        parentId: RelatedId,
        previousRelatedId?: RelatedId | null,
      ) => boolean;
      addMultiRelationWhen: (
        model: CurrentModel,
        id: RelatedId,
        relatedIds: RelatedId[],
        previousRelatedIds: RelatedId[],
      ) => boolean;
      removeMultiRelationWhen: (
        model: CurrentModel,
        id: RelatedId,
        relatedIds: RelatedId[],
        previousRelatedIds: RelatedId[],
      ) => boolean;
    };
  };

  constructor({
    parentTypeName,
    modelTypeName,
    query,
    filters,
  }: {
    parentTypeName: ParentModel['__typename'];
    modelTypeName: CurrentModel['__typename'];
    query: DocumentNode;
    filters: {
      [filter: string]: {
        dataKey: string;
        canSyncWithEmptyData?: boolean;
        addSingleRelationWhen: (
          model: CurrentModel,
          parentId: RelatedId,
          previousRelatedId?: RelatedId | null,
        ) => boolean;
        removeSingleRelationWhen: (
          model: CurrentModel,
          parentId: RelatedId,
          previousRelatedId?: RelatedId | null,
        ) => boolean;
        addMultiRelationWhen: (
          model: CurrentModel,
          id: RelatedId,
          relatedIds: RelatedId[],
          previousRelatedIds: RelatedId[],
        ) => boolean;
        removeMultiRelationWhen: (
          model: CurrentModel,
          id: RelatedId,
          relatedIds: RelatedId[],
          previousRelatedIds: RelatedId[],
        ) => boolean;
      };
    };
  }) {
    this.query = query;

    this.parentTypeName = parentTypeName;
    this.modelTypeName = modelTypeName;
    this.parentModelName = firstLetterToLower(parentTypeName) as Lowercase<
      ParentModel['__typename']
    >;

    this.filters = filters;
  }

  public handleSingleRelationDelta(
    cache: ApolloCache<NormalizedCacheObject>,
    delta: CurrentModel,
    relatedId?: RelatedId | null,
    previousRelatedId?: RelatedId | null,
  ) {
    if (!relatedId) {
      return;
    }

    if (!delta.id) {
      return;
    }

    const currentData = this.readQuery(cache, relatedId);

    if (!currentData) {
      return;
    }

    const result: {
      [k: string]: { id: string; __typename: string }[];
    } = {};

    for (const filter of Object.keys(this.filters)) {
      const filterSetting = this.filters[filter];
      const existingModels:
        | { id: string; __typename: string }[]
        | null
        // @ts-expect-error hard to type this as a record here
        | undefined = currentData[filterSetting.dataKey];

      // in the case that there aren't existing models, that means that
      // no one has queried this resource with this filter. If we were to
      // add the delta to the list, we'd be incorrectly populating the cache
      // with a single item. Some resources, such as checklists and check-items
      // will send deltas that contain all of the items, so we can sync those, while
      // others we can not.
      if (filterSetting.canSyncWithEmptyData !== true && !existingModels) {
        continue;
      }

      if (
        filterSetting.addSingleRelationWhen(delta, relatedId, previousRelatedId)
      ) {
        result[filterSetting.dataKey] = (existingModels || [])
          .filter(({ id }) => id !== delta.id)
          .concat({
            id: delta.id,
            __typename: this.modelTypeName,
          });
      }

      if (
        filterSetting.removeSingleRelationWhen(
          delta,
          relatedId,
          previousRelatedId,
        )
      ) {
        result[filterSetting.dataKey] =
          (existingModels || []).filter(({ id }) => id !== delta.id) || [];
      }
    }

    this.writeModels(cache, relatedId, result);
  }

  public handleMultiRelationDelta(
    cache: ApolloCache<NormalizedCacheObject>,
    delta: CurrentModel,
    relatedIds: RelatedId[] | null,
    previousRelatedIds?: RelatedId[] | null,
  ) {
    if (!delta.id) {
      return;
    }

    if (!Array.isArray(relatedIds)) {
      return;
    }

    if (!Array.isArray(previousRelatedIds)) {
      return;
    }

    const allIds: RelatedId[] = Array.from(
      new Set(relatedIds.concat(previousRelatedIds)),
    ) as RelatedId[];

    for (const id of allIds) {
      const currentData = this.readQuery(cache, id as unknown as RelatedId);

      if (!currentData) {
        continue;
      }

      const result: {
        [k: string]: { id: string; __typename: string }[];
      } = {};

      for (const filter of Object.keys(this.filters)) {
        const filterSetting = this.filters[filter];
        // @ts-expect-error
        const existingModels = currentData[filterSetting.dataKey] || [];

        if (
          filterSetting.addMultiRelationWhen(
            delta,
            id,
            relatedIds,
            previousRelatedIds,
          )
        ) {
          result[filterSetting.dataKey] = existingModels
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .filter(({ id }) => id !== delta.id)
            .concat({
              id: delta.id,
              __typename: this.modelTypeName,
            });
        }

        if (
          filterSetting.removeMultiRelationWhen(
            delta,
            id,
            relatedIds,
            previousRelatedIds,
          )
        ) {
          result[filterSetting.dataKey] = existingModels.filter(
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-shadow
            ({ id }) => id !== delta.id,
          );
        }
      }

      this.writeModels(cache, id, result);
    }
  }

  protected readQuery(
    cache: ApolloCache<NormalizedCacheObject>,
    parentId: RelatedId,
  ) {
    const data = cache.readQuery<QueryType>({
      query: this.query,
      returnPartialData: true,
      variables: {
        parentId,
      },
    });

    return data?.[this.parentModelName];
  }

  protected writeModels(
    cache: ApolloCache<NormalizedCacheObject>,
    parentId: RelatedId,
    result: {
      [k: string]: { id: string; __typename: string }[];
    },
  ) {
    const unmuteMissingFieldErrors = muteMissingFieldErrors();

    cache.writeQuery({
      query: this.query,
      data: {
        __typename: 'Query',
        [`${this.parentModelName}`]: {
          id: parentId,
          __typename: this.parentTypeName,
          ...result,
        },
      },
      variables: {
        parentId,
      },
      broadcast: false,
    });

    unmuteMissingFieldErrors();
  }
}
