/**
 * This originally lived in @trello/config, but that was actually a circular
 * dependency with this package.
 */

// eslint-disable-next-line no-restricted-syntax
export enum AppId {
  Desktop = 'desktop',
}

export interface ClientConfigInit {
  readonly appId?: AppId;
  readonly dontDismissNotifications?: boolean;
  readonly limitMemoryUsage?: boolean;
  readonly desktopVersion?: string;
}
