import { Analytics } from '@trello/atlassian-analytics';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { trelloFetch } from '@trello/fetch';
import { parseNetworkError } from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type {
  MutationDeleteAccessRequestArgs,
  QueryAccessRequestArgs,
  QueryAccessRequestVerificationArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { TrelloRestResolver } from '../types';

export const accessRequestResolver: TrelloRestResolver<
  QueryAccessRequestArgs | null
> = async (_parent, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { modelType, id } = args || {};
  const apiUrl = `/1/${modelType}/${id}/requestAccess`;
  const response = await trelloFetch(
    apiUrl,
    {
      credentials: 'same-origin',
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: `RequestAccess.${modelType}AccessRequest`,
        operationName: context.operationName,
      },
    },
  );

  const statusCode = response.status;

  const result = {
    allowed: true,
    reason: '',
  };
  if (!response.ok) {
    if ([400, 403, 409, 429].includes(statusCode)) {
      result.allowed = false;
      const text = await response.text();
      try {
        /* Response is JSON. */
        const res = JSON.parse(text);
        result.reason = res.message;
      } catch {
        /* Response is plain text. */
        result.reason = text;
      }
    } else {
      throw await parseNetworkError(response);
    }
  }

  return prepareDataForApolloCache(result, rootNode);
};

export const sendAccessRequest: TrelloRestResolver<
  QueryAccessRequestArgs | null
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { modelType, id, traceId } = args || {};
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/${modelType}/${id}/requestAccess`);

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        operationType: 'mutation',
        resolver: `RequestAccess.${modelType}AccessRequest`,
        operationName: context.operationName,
      },
    },
  );

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const accessRequestVerificationResolver: TrelloRestResolver<
  QueryAccessRequestVerificationArgs | null
> = async (_parent, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { modelType, id, idInvitedMember, signature } = args || {};
  let apiUrl = `/1/${modelType}/${id}/accessRequests/${idInvitedMember}`;
  if (signature) {
    apiUrl += `/${signature}`;
  }

  const response = await trelloFetch(
    apiUrl,
    {
      credentials: 'same-origin',
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: `RequestAccess.${modelType}AccessRequest`,
        operationName: context.operationName,
      },
    },
  );

  const result = {
    success: true,
  };
  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(result, rootNode);
};

export const deleteAccessRequest: TrelloRestResolver<
  MutationDeleteAccessRequestArgs | null
> = async (_parent, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { modelType, id, idInvitedMember, signature, traceId } = args || {};
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/${modelType}/${id}/accessRequests/${idInvitedMember}/${signature}`,
  );

  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (traceId) {
    const trelloServerVersion = response.headers.get('X-Trello-Version');
    Analytics.setTrelloServerVersion(traceId, trelloServerVersion);
  }

  const result = {
    success: true,
  };

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(result, rootNode);
};
