import type { GraphQLResolveInfo } from 'graphql';

import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { fetch, trelloFetch } from '@trello/fetch';
import { parseNetworkError } from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type {
  MutationCancelWorkspacePaidAccountArgs,
  MutationExtendTrialPaidSubscriptionArgs,
  MutationPreAuthorizeWorkspaceCreditCardArgs,
  MutationRedeemPromoCodeArgs,
  MutationStartWorkspacePaidSubscriptionArgs,
  MutationUpdateOrganizationBillingContactDetailsArgs,
  MutationUpdateOrganizationBillingInvoiceDetailsArgs,
  MutationUpdateOrganizationCreditCardArgs,
  MutationUpdateOrganizationPaidProductArgs,
  QueryAddMembersPriceQuotesArgs,
  QueryMemberStatementsArgs,
  QueryNewSubscriptionListPriceQuotesArgs,
  QueryNewSubscriptionPriceQuotesArgs,
  QueryOrganizationStatementsArgs,
  QueryRenewalPriceQuotesArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { QueryInfo, ResolverContext, TrelloRestResolver } from '../types';

export const newSubscriptionListPriceQuotesResolver: TrelloRestResolver<
  QueryNewSubscriptionListPriceQuotesArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const queryParams = new URLSearchParams();
  queryParams.set('product', String(args.product));
  if (args.includeUnconfirmed) {
    queryParams.set('includeUnconfirmed', 'true');
  }

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${
      args.accountId
    }/paidAccount/newSubscriptionListPriceQuotesV2?${queryParams.toString()}`,
  );

  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'NewSubscriptionListPriceQuotes',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const quotes = await response.json();
  return prepareDataForApolloCache(quotes, rootNode);
};

export const memberStatementsResolver: TrelloRestResolver<
  QueryMemberStatementsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await trelloFetch(
    networkClient.getUrl(
      `/1/members/${args.accountId}/paidAccount/transactions`,
    ),
    undefined,
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Statements',
        operationName: context.operationName,
      },
    },
  );

  // Intentionally swallowing errors and returning an empty array.
  // Running this query on a free account will result in a 404
  return prepareDataForApolloCache(
    response.ok ? await response.json() : [],
    rootNode,
  );
};

export const organizationStatementsResolver: TrelloRestResolver<
  QueryOrganizationStatementsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await trelloFetch(
    networkClient.getUrl(
      `/1/organizations/${args.accountId}/paidAccount/transactions`,
    ),
    undefined,
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Statements',
        operationName: context.operationName,
      },
    },
  );

  // Intentionally swallowing errors and returning an empty array.
  // Running this query on a free account will result in a 404
  return prepareDataForApolloCache(
    response.ok ? await response.json() : [],
    rootNode,
  );
};

export const newSubscriptionPriceQuotesResolver: TrelloRestResolver<
  QueryNewSubscriptionPriceQuotesArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const queryParams = new URLSearchParams();
  (
    [
      'product',
      'country',
      'postalCode',
      'taxId',
      'stateTaxId',
      'promoCode',
    ] as const
  ).forEach((param) => {
    if (args[param]) {
      queryParams.set(param, String(args[param]));
    }
  });
  if (args.includeUnconfirmed) {
    queryParams.set('includeUnconfirmed', 'true');
  }

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${
      args.accountId
    }/paidAccount/newSubscriptionPriceQuotes?${queryParams.toString()}`,
  );
  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'NewSubscriptionPriceQuotes',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const quotes = await response.json();
  return prepareDataForApolloCache(quotes, rootNode);
};

export const renewalPriceQuotesResolver: TrelloRestResolver<
  QueryRenewalPriceQuotesArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}/paidAccount/renewalPriceQuotes`,
  );
  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'RenewalPriceQuotes',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const quotes = await response.json();
  return prepareDataForApolloCache(quotes, rootNode);
};

export const addMembersPriceQuotesResolver: TrelloRestResolver<
  QueryAddMembersPriceQuotesArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organization/${args.accountId}/paidAccount/addMembersPriceQuotes`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      members: args.members,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const quote = await response.json();
  return prepareDataForApolloCache(quote, rootNode);
};

export const upgradePriceQuotesResolver: TrelloRestResolver<
  QueryNewSubscriptionPriceQuotesArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const queryParams = new URLSearchParams();

  if (args['product']) {
    queryParams.set('product', String(args['product']));
  }

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${
      args.accountId
    }/paidAccount/upgradePriceQuotes?${queryParams.toString()}`,
  );
  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'UpgradePriceQuotes',
      operationType: 'mutation',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const quotes = await response.json();
  return prepareDataForApolloCache(quotes, rootNode);
};

export const preAuthorizeWorkspaceCreditCard: TrelloRestResolver<
  MutationPreAuthorizeWorkspaceCreditCardArgs
> = async (obj, args, context, info) => {
  const networkClient = getNetworkClient();
  const { traceId, idOrganization, product, ...body } = args;
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${idOrganization}/paidAccount/preauthorize`,
  );

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        products: [product.toString()],
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'preAuthorizeWorkspaceCreditCard',
        operationName: context.operationName,
        operationType: 'mutation',
        traceId: traceId ?? undefined,
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const startWorkspacePaidSubscription: TrelloRestResolver<
  MutationStartWorkspacePaidSubscriptionArgs
> = async (obj, args, context, info) => {
  const networkClient = getNetworkClient();
  const { traceId, idOrganization, product, ...body } = args;
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${idOrganization}/paidAccount/startsubscription`,
  );

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        products: [product.toString()],
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'startWorkspacePaidSubscription',
        operationName: context.operationName,
        operationType: 'mutation',
        traceId: traceId ?? undefined,
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const extendTrialPaidSubscription: TrelloRestResolver<
  MutationExtendTrialPaidSubscriptionArgs
> = async (obj, args, context, info) => {
  const networkClient = getNetworkClient();
  const { traceId, idOrganization, product, ...body } = args;
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${idOrganization}/paidAccount/extendTrial`,
  );

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
        products: [product.toString()],
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'extendTrialPaidSubscription',
        operationName: context.operationName,
        operationType: 'mutation',
        traceId: traceId ?? undefined,
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

/**
 * Update the Credit Card on file for a paid account
 */
export const updateOrganizationCreditCard = async (
  obj: object,
  args: MutationUpdateOrganizationCreditCardArgs,
  context: ResolverContext,
  info: QueryInfo | GraphQLResolveInfo,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}/paidAccount/creditCard`,
  );

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nonce: args.nonce,
        country: args.country,
        zipCode: args.zipCode,
        taxId: args.taxId,
        stateTaxId: args.stateTaxId,
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'updateOrganizationCreditCard',
        operationName: context.operationName,
        operationType: 'mutation',
        traceId: args.traceId ?? undefined,
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const model = await response.json();

  return prepareDataForApolloCache(model, rootNode);
};

/**
 * Update / change product associated with paid account.
 * This mutation can be used to switch a monthly account to an annual
 * account, or to renew a cancelled subscription before it expires.
 */
export const updateOrganizationPaidProduct = async (
  obj: object,
  args: MutationUpdateOrganizationPaidProductArgs,
  context: ResolverContext,
  info: QueryInfo | GraphQLResolveInfo,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}/paidAccount/products`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      products: args.products.toString(),
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const updateOrganizationBillingContactDetails = async (
  obj: object,
  args: MutationUpdateOrganizationBillingContactDetailsArgs,
  context: ResolverContext,
  info: QueryInfo | GraphQLResolveInfo,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}/paidAccount/billingContact`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      billingContactFullName: args.contactName,
      billingContactEmail: args.contactEmail,
      billingContactLocale: args.contactLocale,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const updateOrganizationBillingInvoiceDetails = async (
  obj: object,
  args: MutationUpdateOrganizationBillingInvoiceDetailsArgs,
  context: ResolverContext,
  info: QueryInfo | GraphQLResolveInfo,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}/paidAccount/invoice`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      invoiceDetails: args.invoiceDetails,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const cancelWorkspacePaidAccount = async (
  obj: object,
  args: MutationCancelWorkspacePaidAccountArgs,
  context: ResolverContext,
  info: QueryInfo | GraphQLResolveInfo,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}/paidAccount/cancel`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const redeemPromoCode: TrelloRestResolver<
  MutationRedeemPromoCodeArgs
> = async (obj, args, context, info) => {
  const networkClient = getNetworkClient();
  const redeemUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}/redeem`,
  );

  const redeemResponse = await trelloFetch(
    redeemUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: args.promoCode,
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'redeemPromoCode',
        operationName: context.operationName,
        operationType: 'mutation',
      },
    },
  );

  if (!redeemResponse.ok) {
    sendNetworkErrorEvent({
      url: redeemUrl,
      response: await redeemResponse.clone().text(),
      status: redeemResponse.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(redeemResponse);
  }

  const paidAccountUrl = networkClient.getUrl(
    `/1/organizations/${args.accountId}?fields=credits,offering&paidAccount=true&paidAccount_fields=all`,
  );

  const paidAccountResponse = await trelloFetch(paidAccountUrl);
  if (!paidAccountResponse.ok) {
    sendNetworkErrorEvent({
      url: redeemUrl,
      response: await paidAccountResponse.clone().text(),
      status: paidAccountResponse.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(paidAccountResponse);
  }

  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const model = await paidAccountResponse.json();
  return prepareDataForApolloCache(model, rootNode);
};
