import { token } from '@trello/theme';

import { itemRotation } from './itemRotation';

type LabelColor =
  | 'green'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'purple'
  | 'blue'
  | 'sky'
  | 'lime'
  | 'pink'
  | 'black';

type ColorShade =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

// NOTE: ADS tokens only have 4 viable options to use in rotations.
// For now, we're continuing to use 9 different fallback colors in order to
// effectively feature flag the changes, but once ADS tokens are released,
// we can drop these to 4 values per color.
const labelRotationColors: Record<LabelColor, Record<ColorShade, string>> = {
  green: {
    '100': token('color.background.accent.green.subtle', '#4BCE97'),
    '200': token('color.background.accent.green.subtler', '#BAF3DB'),
    '300': token('color.background.accent.green.subtlest', '#DCFFF1'),
    '400': token('color.background.accent.green.bolder', '#1F845A'),
    '500': token('color.background.accent.green.subtle', '#4BCE97'),
    '600': token('color.background.accent.green.subtler', '#BAF3DB'),
    '700': token('color.background.accent.green.subtlest', '#DCFFF1'),
    '800': token('color.background.accent.green.bolder', '#1F845A'),
    '900': token('color.background.accent.green.bolder', '#1F845A'),
  },
  yellow: {
    '100': token('color.background.accent.yellow.subtle', '#F5CD47'),
    '200': token('color.background.accent.yellow.subtler', '#F8E6A0'),
    '300': token('color.background.accent.yellow.subtlest', '#FFF7D6'),
    '400': token('color.background.accent.yellow.bolder', '#946F00'),
    '500': token('color.background.accent.yellow.subtle', '#F5CD47'),
    '600': token('color.background.accent.yellow.subtler', '#F8E6A0'),
    '700': token('color.background.accent.yellow.subtlest', '#FFF7D6'),
    '800': token('color.background.accent.yellow.bolder', '#946F00'),
    '900': token('color.background.accent.yellow.bolder', '#946F00'),
  },
  orange: {
    '100': token('color.background.accent.orange.subtle', '#FEA362'),
    '200': token('color.background.accent.orange.subtler', '#FEDEC8'),
    '300': token('color.background.accent.orange.subtlest', '#FFF3EB'),
    '400': token('color.background.accent.orange.bolder', '#C25100'),
    '500': token('color.background.accent.orange.subtle', '#FEA362'),
    '600': token('color.background.accent.orange.subtler', '#FEDEC8'),
    '700': token('color.background.accent.orange.subtlest', '#FFF3EB'),
    '800': token('color.background.accent.orange.bolder', '#C25100'),
    '900': token('color.background.accent.orange.bolder', '#C25100'),
  },
  red: {
    '100': token('color.background.accent.red.subtle', '#F87168'),
    '200': token('color.background.accent.red.subtler', '#FFD5D2'),
    '300': token('color.background.accent.red.subtlest', '#FFECEB'),
    '400': token('color.background.accent.red.bolder', '#C9372C'),
    '500': token('color.background.accent.red.subtle', '#F87168'),
    '600': token('color.background.accent.red.subtler', '#FFD5D2'),
    '700': token('color.background.accent.red.subtlest', '#FFECEB'),
    '800': token('color.background.accent.red.bolder', '#C9372C'),
    '900': token('color.background.accent.red.bolder', '#C9372C'),
  },
  purple: {
    '100': token('color.background.accent.purple.subtle', '#9F8FEF'),
    '200': token('color.background.accent.purple.subtler', '#DFD8FD'),
    '300': token('color.background.accent.purple.subtlest', '#F3F0FF'),
    '400': token('color.background.accent.purple.bolder', '#6E5DC6'),
    '500': token('color.background.accent.purple.subtle', '#9F8FEF'),
    '600': token('color.background.accent.purple.subtler', '#DFD8FD'),
    '700': token('color.background.accent.purple.subtlest', '#F3F0FF'),
    '800': token('color.background.accent.purple.bolder', '#6E5DC6'),
    '900': token('color.background.accent.purple.bolder', '#6E5DC6'),
  },
  blue: {
    '100': token('color.background.accent.blue.subtle', '#579DFF'),
    '200': token('color.background.accent.blue.subtler', '#CCE0FF'),
    '300': token('color.background.accent.blue.subtlest', '#E9F2FF'),
    '400': token('color.background.accent.blue.bolder', '#0C66E4'),
    '500': token('color.background.accent.blue.subtle', '#579DFF'),
    '600': token('color.background.accent.blue.subtler', '#CCE0FF'),
    '700': token('color.background.accent.blue.subtlest', '#E9F2FF'),
    '800': token('color.background.accent.blue.bolder', '#0C66E4'),
    '900': token('color.background.accent.blue.bolder', '#0C66E4'),
  },
  sky: {
    '100': token('color.background.accent.teal.subtle', '#6CC3E0'),
    '200': token('color.background.accent.teal.subtler', '#C6EDFB'),
    '300': token('color.background.accent.teal.subtlest', '#E7F9FF'),
    '400': token('color.background.accent.teal.bolder', '#227D9B'),
    '500': token('color.background.accent.teal.subtle', '#6CC3E0'),
    '600': token('color.background.accent.teal.subtler', '#C6EDFB'),
    '700': token('color.background.accent.teal.subtlest', '#E7F9FF'),
    '800': token('color.background.accent.teal.bolder', '#227D9B'),
    '900': token('color.background.accent.teal.bolder', '#227D9B'),
  },
  lime: {
    '100': token('color.background.accent.lime.subtle', '#94C748'),
    '200': token('color.background.accent.lime.subtler', '#D3F1A7'),
    '300': token('color.background.accent.lime.subtlest', '#EFFFD6'),
    '400': token('color.background.accent.lime.bolder', '#5B7F24'),
    '500': token('color.background.accent.lime.subtle', '#94C748'),
    '600': token('color.background.accent.lime.subtler', '#D3F1A7'),
    '700': token('color.background.accent.lime.subtlest', '#EFFFD6'),
    '800': token('color.background.accent.lime.bolder', '#5B7F24'),
    '900': token('color.background.accent.lime.bolder', '#5B7F24'),
  },
  pink: {
    '100': token('color.background.accent.magenta.subtle', '#E774BB'),
    '200': token('color.background.accent.magenta.subtler', '#FDD0EC'),
    '300': token('color.background.accent.magenta.subtlest', '#FFECF8'),
    '400': token('color.background.accent.magenta.bolder', '#AE4787'),
    '500': token('color.background.accent.magenta.subtle', '#E774BB'),
    '600': token('color.background.accent.magenta.subtler', '#FDD0EC'),
    '700': token('color.background.accent.magenta.subtlest', '#FFECF8'),
    '800': token('color.background.accent.magenta.bolder', '#AE4787'),
    '900': token('color.background.accent.magenta.bolder', '#AE4787'),
  },
  black: {
    '100': token('color.background.accent.gray.subtle', '#8590A2'),
    '200': token('color.background.accent.gray.subtler', '#DCDFE4'),
    '300': token('color.background.accent.gray.subtlest', '#F1F2F4'),
    '400': token('color.background.accent.gray.bolder', '#626F86'),
    '500': token('color.background.accent.gray.subtle', '#8590A2'),
    '600': token('color.background.accent.gray.subtler', '#DCDFE4'),
    '700': token('color.background.accent.gray.subtlest', '#F1F2F4'),
    '800': token('color.background.accent.gray.bolder', '#626F86'),
    '900': token('color.background.accent.gray.bolder', '#626F86'),
  },
};

const defaultShadeOrder: ColorShade[] = [
  '500',
  '700',
  '900',
  '200',
  '400',
  '600',
  '800',
  '100',
  '300',
];

const createLabelColorRotation = (
  labelColor: LabelColor,
  shadeOrder: ColorShade[] = defaultShadeOrder,
): Generator<string> => {
  return itemRotation(
    shadeOrder.map((colorShade) => {
      const color = labelRotationColors[labelColor][colorShade];
      return color;
    }),
  );
};

/*
 * Creates an object mapping label colors to color generators to represent
 * that label on dashboard charts
 *
 * These rotations solve a problem on the dashboard where we need to represent
 * multiple labels that are the same color on the same chart, e.g. two `green`
 * labels shown next to each other on a pie chart would appear to just be one big
 * pie section if they were shown as the same color.
 *
 * We solve this by rotating through different shades of label colors, and this function
 * provides generators to facilitate that
 */
export const labelColorRotations = (): Record<
  LabelColor,
  Generator<string>
> => ({
  green: createLabelColorRotation('green'),
  blue: createLabelColorRotation('blue'),
  pink: createLabelColorRotation('pink'),
  lime: createLabelColorRotation('lime'),
  sky: createLabelColorRotation('sky'),
  yellow: createLabelColorRotation('yellow'),
  orange: createLabelColorRotation('orange'),
  purple: createLabelColorRotation('purple'),
  red: createLabelColorRotation('red'),
  black: createLabelColorRotation('black', [
    '900',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
  ]),
});
