import { isShortLink } from '@trello/id-cache';

// eslint-disable-next-line no-restricted-syntax
enum RouteId {
  OPEN_SOURCE_ATTRIBUTIONS = 'attributions',
  BOARD = 'board',
  BOARD_BUTLER = 'board-butler',
  BOARD_BUTLER_PAGE = 'board-butler-page',
  BOARD_RECOMMEND = 'board-recommend',
  CARD = 'card',
  ENTERPRISE_ADMIN = 'enterprise-admin',
  ENTERPRISE_ADMIN_TAB = 'enterprise-admin-tab',
  ENTERPRISE_ADMIN_TAB_SUBSECTION = 'enterprise-admin-tab-subsection',
  TEMPLATES = 'templates',
  TEMPLATES_CATEGORIES = 'templates-categories',
  TEMPLATES_STORY = 'templates-story',
  TEMPLATES_RECOMMEND = 'templates-recommend',
  USER = 'user',
  USER_TABS = 'user-tabs',
  USER_CARDS_WORKSPACE = 'user-cards-workspace',
  VIEW = 'view',
  WORKSPACE = 'workspace',
  WORKSPACE_SECTION = 'workspace-section',
  WORKSPACE_MEMBERS_SUBSECTION = 'workspace-members-subsection',
  WORKSPACE_VIEWS_SUBSECTION = 'workspace-views-subsection',
  POWER_UPS_HOME = 'power-ups-home',
  POWER_UPS_ALL = 'power-ups-all',
  POWER_UPS_TRELLO = 'power-ups-trello',
  POWER_UPS_CATEGORIES = 'power-ups-categories',
  UPGRADE_TEAM = 'select-team-to-upgrade',
  SHORTCUTS = 'shortcuts',
  HOME = 'home',
}

// indices of non-UGC/PII in the pathname depending on the route
const ALLOWLIST_INDICES = {
  ROUTE_IDENTIFIER: 0,
  ROUTE_SHORTLINK: 1,
  B_ROUTE_BUTLER: 3,
  B_ROUTE_BUTLER_PAGE: 4,
  B_ROUTE_RECOMMEND: 4,
  E_ROUTE_ADMIN: 2,
  E_ROUTE_ADMIN_TAB_NAME: 3,
  E_ROUTE_ADMIN_TAB_SUBSECTION_NAME: 4,
  POWER_UPS_ROUTE_PAGE: 1,
  POWER_UPS_ROUTE_CATEGORY: 1,
  POWER_UPS_ROUTE_CATEGORY_NAME: 2,
  TEMPLATES_ROUTE_PAGE: 1,
  TEMPLATES_ROUTE_CATEGORY: 1,
  TEMPLATES_ROUTE_TEMPLATE_NAME: 2,
  TEMPLATES_ROUTE_RECOMMEND: 4,
  U_ROUTE_TAB_NAME: 2,
  W_ROUTE_PAGE: 2,
  W_ROUTE_PAGE_SUBSECTION: 3,
};
interface RouteDefinition {
  id: RouteId;
  regExp: RegExp;
  allowlist: number[];
}
const routePatternMap = new Map<RouteId, RouteDefinition>([
  [
    RouteId.OPEN_SOURCE_ATTRIBUTIONS,
    {
      id: RouteId.OPEN_SOURCE_ATTRIBUTIONS,
      regExp: new RegExp('^/attributions$'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
  [
    RouteId.BOARD_RECOMMEND,
    {
      id: RouteId.BOARD_RECOMMEND,
      regExp: new RegExp('^/b/([^/]+)/([^/]+)/([^/]+)/recommend$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.ROUTE_SHORTLINK,
        ALLOWLIST_INDICES.B_ROUTE_RECOMMEND,
      ],
    },
  ],
  [
    RouteId.BOARD_BUTLER_PAGE,
    {
      id: RouteId.BOARD_BUTLER_PAGE,
      regExp: new RegExp(
        '^/b/([^/]+)/([^/]+)/butler/(rules|card-buttons|board-buttons|schedule|on-dates|connected-apps)$',
      ),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.ROUTE_SHORTLINK,
        ALLOWLIST_INDICES.B_ROUTE_BUTLER,
        ALLOWLIST_INDICES.B_ROUTE_BUTLER_PAGE,
      ],
    },
  ],
  [
    RouteId.BOARD_BUTLER,
    {
      id: RouteId.BOARD_BUTLER,
      regExp: new RegExp('^/b/([^/]+)/([^/]+)/butler/$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.ROUTE_SHORTLINK,
        ALLOWLIST_INDICES.B_ROUTE_BUTLER,
      ],
    },
  ],
  [
    RouteId.BOARD,
    {
      id: RouteId.BOARD,
      regExp: new RegExp('^/b/([^/]+)(?:/(.*?))?$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.ROUTE_SHORTLINK,
      ],
    },
  ],
  [
    RouteId.CARD,
    {
      id: RouteId.CARD,
      regExp: new RegExp('^/c/([a-zA-Z0-9]{8})(?:[/@](.*?))?$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.ROUTE_SHORTLINK,
      ],
    },
  ],
  [
    RouteId.ENTERPRISE_ADMIN_TAB_SUBSECTION,
    {
      id: RouteId.ENTERPRISE_ADMIN_TAB_SUBSECTION,
      regExp: new RegExp(
        '^/e/([^/]+)/admin/(members|workspaces|boards)/(managed|boardguests|deactivated|non-enterprise|pending|public)$',
      ),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.E_ROUTE_ADMIN,
        ALLOWLIST_INDICES.E_ROUTE_ADMIN_TAB_NAME,
        ALLOWLIST_INDICES.E_ROUTE_ADMIN_TAB_SUBSECTION_NAME,
      ],
    },
  ],
  [
    RouteId.ENTERPRISE_ADMIN_TAB,
    {
      id: RouteId.ENTERPRISE_ADMIN_TAB,
      regExp: new RegExp(
        '^/e/([^/]+)/admin/(members|workspaces|settings|attachment-restrictions|boards|power-ups|auditlog|account-administration|sso|api-tokens)$',
      ),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.E_ROUTE_ADMIN,
        ALLOWLIST_INDICES.E_ROUTE_ADMIN_TAB_NAME,
      ],
    },
  ],
  [
    RouteId.ENTERPRISE_ADMIN,
    {
      id: RouteId.ENTERPRISE_ADMIN,
      regExp: new RegExp('^/e/([^/]+)/admin$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.E_ROUTE_ADMIN,
      ],
    },
  ],
  [
    RouteId.POWER_UPS_HOME,
    {
      id: RouteId.POWER_UPS_HOME,
      regExp: new RegExp('^/power-ups$'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
  [
    RouteId.POWER_UPS_ALL,
    {
      id: RouteId.POWER_UPS_ALL,
      regExp: new RegExp('^/power-ups/all$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.POWER_UPS_ROUTE_PAGE,
      ],
    },
  ],
  [
    RouteId.POWER_UPS_TRELLO,
    {
      id: RouteId.POWER_UPS_TRELLO,
      regExp: new RegExp('^/power-ups/made-by-trello$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.POWER_UPS_ROUTE_PAGE,
      ],
    },
  ],
  [
    RouteId.POWER_UPS_CATEGORIES,
    {
      id: RouteId.POWER_UPS_CATEGORIES,
      regExp: new RegExp(
        '^/power-ups/category/(automation|analytics-reporting|board-utilities|communication-collaboration|developer-tools|file-management|hr-operations|it-project-management|marketing-social-media|product-design|sales-support)$',
      ),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.POWER_UPS_ROUTE_CATEGORY,
        ALLOWLIST_INDICES.POWER_UPS_ROUTE_CATEGORY_NAME,
      ],
    },
  ],
  [
    RouteId.HOME,
    {
      id: RouteId.HOME,
      regExp: new RegExp('^/$'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
  [
    RouteId.UPGRADE_TEAM,
    {
      id: RouteId.UPGRADE_TEAM,
      regExp: new RegExp('^/select-team-to-upgrade$'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
  [
    RouteId.SHORTCUTS,
    {
      id: RouteId.SHORTCUTS,
      regExp: new RegExp('^/shortcuts$'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
  [
    RouteId.TEMPLATES_RECOMMEND,
    {
      id: RouteId.TEMPLATES_RECOMMEND,
      regExp: new RegExp('^/templates/([^/]+)/([^/]+)/([^/]+)/recommend$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.TEMPLATES_ROUTE_CATEGORY,
        ALLOWLIST_INDICES.TEMPLATES_ROUTE_TEMPLATE_NAME,
        ALLOWLIST_INDICES.TEMPLATES_ROUTE_RECOMMEND,
      ],
    },
  ],
  [
    RouteId.TEMPLATES_CATEGORIES,
    {
      id: RouteId.TEMPLATES_CATEGORIES,
      regExp: new RegExp(
        '^/templates/(business|design|education|engineering|marketing|operations-hr|personal|productivity|product-management|project-management|remote-work|sales|support|team-management)$',
      ),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.TEMPLATES_ROUTE_CATEGORY,
      ],
    },
  ],
  [
    RouteId.TEMPLATES_STORY,
    {
      id: RouteId.TEMPLATES_STORY,
      regExp: new RegExp('^/templates/([^/]+)/([^/]+)$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.TEMPLATES_ROUTE_CATEGORY,
        ALLOWLIST_INDICES.TEMPLATES_ROUTE_TEMPLATE_NAME,
      ],
    },
  ],
  [
    RouteId.TEMPLATES,
    {
      id: RouteId.TEMPLATES,
      regExp: new RegExp('^/templates$'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
  [
    RouteId.USER_CARDS_WORKSPACE,
    {
      id: RouteId.USER_CARDS_WORKSPACE,
      regExp: new RegExp('^/u/([^/]+)/cards/([^/]+)$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.U_ROUTE_TAB_NAME,
      ],
    },
  ],
  [
    RouteId.USER_TABS,
    {
      id: RouteId.USER_TABS,
      regExp: new RegExp(
        '^/u/([^/]+)/(profile|activity|boards|cards|labs|tasks)$',
      ),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.U_ROUTE_TAB_NAME,
      ],
    },
  ],
  [
    RouteId.USER,
    {
      id: RouteId.USER,
      regExp: new RegExp('^/u/'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
  [
    RouteId.VIEW,
    {
      id: RouteId.VIEW,
      regExp: new RegExp('^/v/'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.ROUTE_SHORTLINK,
      ],
    },
  ],
  [
    RouteId.WORKSPACE_SECTION,
    {
      id: RouteId.WORKSPACE_SECTION,
      regExp: new RegExp(
        '^/w/([^/]+)/(account|billing|home|export|free-trial|members|tables|getting-started|highlights|reports)$',
      ),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.W_ROUTE_PAGE,
      ],
    },
  ],
  [
    RouteId.WORKSPACE_MEMBERS_SUBSECTION,
    {
      id: RouteId.WORKSPACE_MEMBERS_SUBSECTION,
      regExp: new RegExp('^/w/([^/]+)/members/(guests|requests)$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.W_ROUTE_PAGE,
        ALLOWLIST_INDICES.W_ROUTE_PAGE_SUBSECTION,
      ],
    },
  ],
  [
    RouteId.WORKSPACE_VIEWS_SUBSECTION,
    {
      id: RouteId.WORKSPACE_VIEWS_SUBSECTION,
      regExp: new RegExp('^/w/([^/]+)/views/(table|calendar|)$'),
      allowlist: [
        ALLOWLIST_INDICES.ROUTE_IDENTIFIER,
        ALLOWLIST_INDICES.W_ROUTE_PAGE,
        ALLOWLIST_INDICES.W_ROUTE_PAGE_SUBSECTION,
      ],
    },
  ],
  [
    RouteId.WORKSPACE,
    {
      id: RouteId.WORKSPACE,
      regExp: new RegExp('^/w/'),
      allowlist: [ALLOWLIST_INDICES.ROUTE_IDENTIFIER],
    },
  ],
]);

const determineRoute = (urlPathname: string) => {
  for (const [, routeDefinition] of routePatternMap) {
    if (urlPathname.match(routeDefinition.regExp)) {
      return routeDefinition;
    }
  }
};

// reconstructs the pathname to include sections that DO NOT include UGC/PII
const reconstructPathname = (
  pathnameSections: string[],
  sectionsToKeep: number[],
) => {
  let reconstructedUrl = '';
  for (let i = 0; i < pathnameSections.length; i++) {
    if (sectionsToKeep.includes(i)) {
      reconstructedUrl += '/' + pathnameSections[i];
    }
  }
  return reconstructedUrl;
};

// finds the matching route for the pathname we want to include in our marketing
// analytics and then sanitizes it of UGC/PII
export const scrubMarketingUrl = (urlPathname: string, screenName: string) => {
  const matchingRoute = determineRoute(urlPathname);
  // remove starting slash to allow matchers to use ^ properly
  const partialUrl = urlPathname.substring(1, urlPathname.length);
  const pathnameSections = `${partialUrl}`.split('/');

  // Validate shortlinks, do not return marketing URL if shortlinks
  // are invalid
  if (
    pathnameSections[0] === 'b' ||
    pathnameSections[0] === 'c' ||
    pathnameSections[0] === 'v'
  ) {
    if (!isShortLink(pathnameSections[1])) {
      return '/';
    }
  }
  // to avoid UGC/PII related HOTs, we should be extra cautious & return a
  // generic screen name for URLs that don't follow a known pattern
  return matchingRoute
    ? reconstructPathname(pathnameSections, matchingRoute.allowlist)
    : `/${screenName}`;
};
