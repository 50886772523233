import { SharedState } from '@trello/shared-state';

// eslint-disable-next-line no-restricted-syntax
export enum PollingState {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  FORCE_DISCONNECTED = 'force_disconnected',
  TOO_FAR_BEHIND = 'too_far_behind',
  RATE_LIMITED = 'rate_limited',
  TERMINATING_ERROR_RESPONSE = 'terminating error response',
}

/**
 * Shared state that broadcasts the current ws state
 */
export const pollingState = new SharedState<PollingState>(
  PollingState.DISCONNECTED,
);
