import * as Types from '@trello/graphql/generated';

import { useQuickLoad } from '@trello/quickload';
import type { TrelloQueryHookOptions, TrelloLazyQueryHookOptions, TrelloSuspenseQueryHookOptions } from '@trello/quickload';
import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const MemberContextDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MemberContextData"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"member"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"StringValue","value":"me","block":false}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"logins"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"claimable"}}]}},{"kind":"Field","name":{"kind":"Name","value":"organizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"offering"}}]}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"MemberContextData","document":MemberContextDataDocument}} as const;
export type MemberContextDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MemberContextDataQuery = (
  { __typename: 'Query' }
  & { member?: Types.Maybe<(
    { __typename: 'Member' }
    & Pick<Types.Member, 'id'>
    & { logins: Array<(
      { __typename: 'Login' }
      & Pick<Types.Login, 'id' | 'claimable'>
    )>, organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Types.Organization, 'id' | 'offering'>
    )> }
  )> }
);

/**
 * __useMemberContextDataQuery__
 *
 * To run a query within a React component, call `useMemberContextDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberContextDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberContextDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberContextDataQuery(
  baseOptions?: TrelloQueryHookOptions<
    MemberContextDataQuery,
    MemberContextDataQueryVariables
  >,
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: MemberContextDataDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  const result = Apollo.useQuery<
    MemberContextDataQuery,
    MemberContextDataQueryVariables
  >(MemberContextDataDocument, options);
  // reconstruct result because modifying the useQuery result actually changes apollo behavior because of memoization
  const trelloResult = { ...result };
  if (isQuickLoading) {
    trelloResult.loading = true;
  }

  return trelloResult;
}
export function useMemberContextDataLazyQuery(
  baseOptions?: TrelloLazyQueryHookOptions<
    MemberContextDataQuery,
    MemberContextDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MemberContextDataQuery,
    MemberContextDataQueryVariables
  >(MemberContextDataDocument, options);
}
export function useMemberContextDataSuspenseQuery(
  baseOptions?: TrelloSuspenseQueryHookOptions<
    MemberContextDataQuery,
    MemberContextDataQueryVariables
  >,
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: MemberContextDataDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  return Apollo.useSuspenseQuery<
    MemberContextDataQuery,
    MemberContextDataQueryVariables
  >(MemberContextDataDocument, options);
}
export type MemberContextDataQueryHookResult = ReturnType<
  typeof useMemberContextDataQuery
>;
export type MemberContextDataLazyQueryHookResult = ReturnType<
  typeof useMemberContextDataLazyQuery
>;
export type MemberContextDataSuspenseQueryHookResult = ReturnType<
  typeof useMemberContextDataSuspenseQuery
>;
export type MemberContextDataQueryResult = Apollo.QueryResult<
  MemberContextDataQuery,
  MemberContextDataQueryVariables
>;
