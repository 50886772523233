import type { FunctionComponent, SuspenseProps } from 'react';
import { startTransition, Suspense, useEffect, useState } from 'react';

/**
 * The React Suspense API has a critical issue: when multiple lazy components
 * are nested in the same tree, loading states are hoisted up to the first
 * Suspense, rather than the closest one. This means that the entire Suspense
 * tree would remain in its fallback state while its children are loading, which
 * is clearly a bug.
 *
 * Worse, React concurrent mode (18/19) seems to have an even more severe issue
 * with this behavior, wherein nested Suspenses can trigger rapid rerenders
 * (non-deterministic, potentially hundreds or thousands of times).
 *
 * As a workaround for this, use this component to replace React's Suspense,
 * which uses transitions to defer its render from the initial render. This can
 * be used safely in place of all Suspense implementations, but is especially
 * preferred when the lazy component could be nested behind another one.
 *
 * https://react.dev/reference/react/useTransition#preventing-unwanted-loading-indicators
 *
 * @example
 *
 * // Note that this top-level Suspense could be a DeferredSuspense, too:
 * <Suspense fallback={null}>
 *   <LazyParentComponent>
 *     <DeferredSuspense fallback={null}>
 *       <LazyChildComponent />
 *     </DeferredSuspense>
 *   </LazyParentComponent>
 * </Suspense>
 */
export const DeferredSuspense: FunctionComponent<SuspenseProps> = (props) => {
  const [isDeferred, setIsDeferred] = useState(true);

  useEffect(() => {
    startTransition(() => setIsDeferred(false));
  }, []);

  if (isDeferred) {
    return props.fallback;
  }

  return <Suspense {...props} />;
};
