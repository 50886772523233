// eslint-disable-next-line no-restricted-syntax
export enum MemberErrorExtensions {
  MUST_REACTIVATE_USER_FIRST = 'MUST_REACTIVATE_USER_FIRST',
  BAD_EMAIL = 'BAD_EMAIL',
  ALREADY_CONFIRMED_EMAIL = 'ALREADY_CONFIRMED_EMAIL',
}

export const MemberErrors: Record<string, MemberErrorExtensions> = {
  'Must reactivate user first':
    MemberErrorExtensions.MUST_REACTIVATE_USER_FIRST,
  'Invalid email address': MemberErrorExtensions.BAD_EMAIL,
  'This email address is already confirmed':
    MemberErrorExtensions.ALREADY_CONFIRMED_EMAIL,
};
