import { useCallback, useEffect, useState } from 'react';

import { useSharedStateSelector } from '@trello/shared-state';

import type { featureExperiments } from './data/featureGates';
import { localOverridesState } from './localOverridesState';

export const useIsExperimentValueOverrideChanged = (
  experimentName: keyof typeof featureExperiments,
) => {
  const override = useSharedStateSelector(
    localOverridesState,
    useCallback(
      (overrideState) => {
        return overrideState.configs[experimentName] ?? null;
      },
      [experimentName],
    ),
  );

  const [prevLocalOverride, setPrevLocalOverride] = useState<
    typeof override | null
  >(null);

  useEffect(() => {
    if (override !== prevLocalOverride) {
      setPrevLocalOverride(override);
    }
  }, [override, prevLocalOverride]);

  return override !== prevLocalOverride;
};
