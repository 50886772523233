import { token } from '@trello/theme';

export const LabelColorMap: Record<string, string> = {
  green_light: token('color.background.accent.green.subtler', '#BAF3DB'),
  green: token('color.background.accent.green.subtle', '#4BCE97'),
  green_dark: token('color.background.accent.green.bolder', '#1F845A'),
  yellow_light: token('color.background.accent.yellow.subtler', '#F8E6A0'),
  yellow: token('color.background.accent.yellow.subtle', '#F5CD47'),
  yellow_dark: token('color.background.accent.yellow.bolder', '#946F00'),
  orange_light: token('color.background.accent.orange.subtler', '#FEDEC8'),
  orange: token('color.background.accent.orange.subtle', '#FEA362'),
  orange_dark: token('color.background.accent.orange.bolder', '#C25100'),
  red_light: token('color.background.accent.red.subtler', '#FFD5D2'),
  red: token('color.background.accent.red.subtle', '#F87168'),
  red_dark: token('color.background.accent.red.bolder', '#C9372C'),
  purple_light: token('color.background.accent.purple.subtler', '#DFD8FD'),
  purple: token('color.background.accent.purple.subtle', '#9F8FEF'),
  purple_dark: token('color.background.accent.purple.bolder', '#6E5DC6'),
  blue_light: token('color.background.accent.blue.subtler', '#CCE0FF'),
  blue: token('color.background.accent.blue.subtle', '#579DFF'),
  blue_dark: token('color.background.accent.blue.bolder', '#0C66E4'),
  sky_light: token('color.background.accent.teal.subtler', '#C6EDFB'),
  sky: token('color.background.accent.teal.subtle', '#6CC3E0'),
  sky_dark: token('color.background.accent.teal.bolder', '#227D9B'),
  lime_light: token('color.background.accent.lime.subtler', '#D3F1A7'),
  lime: token('color.background.accent.lime.subtle', '#94C748'),
  lime_dark: token('color.background.accent.lime.bolder', '#5B7F24'),
  pink_light: token('color.background.accent.magenta.subtler', '#FDD0EC'),
  pink: token('color.background.accent.magenta.subtle', '#E774BB'),
  pink_dark: token('color.background.accent.magenta.bolder', '#AE4787'),
  black_light: token('color.background.accent.gray.subtler', '#DCDFE4'),
  black: token('color.background.accent.gray.subtle', '#8590A2'),
  black_dark: token('color.background.accent.gray.bolder', '#626F86'),
  null: token('color.background.neutral', '#091E420F'),
};
