/** @deprecated */
// eslint-disable-next-line no-restricted-syntax
export enum RouteNames_DO_NOT_USE {
  ROOT = 'root',
  BOARD = 'board',
  CARD = 'card',
  PROFILE = 'profile',
  SEARCH = 'search',
  SHORTCUTS = 'shortcuts',
  POWER_UPS = 'power-ups',
  UNKNOWN = 'unknown',
  WORKSPACE = 'workspace',
}
