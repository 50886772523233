import FeatureGates from '@atlaskit/feature-gate-js-client';
import { deepEqual } from '@trello/objects';

import { featureGateClientInitializationState } from './featureGateClientInitializationState';
import {
  featureGatesClientWrapper,
  type FeatureGatesClientWrapperParams,
} from './featureGatesClientWrapper';

let initPromise: Promise<void> | undefined;

const previousInitArgs = {
  identifiers: {},
  customAttributes: {},
};

export const initFeatureGateClientWithWrapper = async (
  params: FeatureGatesClientWrapperParams,
) =>
  featureGatesClientWrapper({ ...params, step: 'init' })(
    ({ fetchOptions, identifiers, customAttributes }) => {
      if (!FeatureGates.initializeCalled()) {
        previousInitArgs.identifiers = identifiers;
        previousInitArgs.customAttributes = customAttributes;
        initPromise = FeatureGates.initialize(
          fetchOptions,
          identifiers,
          customAttributes,
        ).finally(() => {
          featureGateClientInitializationState.setValue({
            isInitialized: true,
            identifiers,
            customAttributes,
          });
        });
      } else if (
        !deepEqual(previousInitArgs, { identifiers, customAttributes })
      ) {
        previousInitArgs.identifiers = identifiers;
        previousInitArgs.customAttributes = customAttributes;

        initPromise = FeatureGates.updateUser(
          fetchOptions,
          identifiers,
          customAttributes,
        ).finally(() => {
          featureGateClientInitializationState.setValue({
            identifiers,
            customAttributes,
          });
        });
      }
      return initPromise;
    },
  );
