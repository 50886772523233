import { featureFlagClient } from '@trello/feature-flag-client';

import { connectionInformationState } from './connectionInformationState';

export interface ConnectionInformationEvent {
  eventName: string;
  payload?: string | object;
  timestamp?: number;
}

export interface PollingConnectionEvent extends ConnectionInformationEvent {
  source: 'pollingConnection';
}

export interface SocketConnectionEvent extends ConnectionInformationEvent {
  source: 'socketConnection';
}

export interface RealtimeUpdaterEvent extends ConnectionInformationEvent {
  source: 'realtimeUpdater';
}

export interface WebsocketEvent extends ConnectionInformationEvent {
  source: 'websocket';
}

export interface WebSocketStateEvent extends ConnectionInformationEvent {
  source: 'webSocketState';
}

export interface GenericEvent extends ConnectionInformationEvent {
  source: 'generic';
}

export function logConnectionInformation(
  event:
    | PollingConnectionEvent
    | SocketConnectionEvent
    | RealtimeUpdaterEvent
    | WebsocketEvent
    | WebSocketStateEvent
    | GenericEvent,
) {
  if (featureFlagClient.get('fep.log-connection-information', false)) {
    connectionInformationState.setValue({
      eventLog: [
        {
          ...event,
          timestamp: Date.now(),
        },
        // Slice the array so we don't exceed the event log limit. This helps avoid every-increasing memory usage.
        ...connectionInformationState.value.eventLog.slice(
          0,
          connectionInformationState.value.eventLogLimit - 1,
        ),
      ],
    });
  }
}
