import { checkAri } from './checkAri';
import { checkId } from './checkId';
import { isShortLink } from './isShortLink';

type IdCacheType = 'Card' | 'Board' | 'Workspace';
type IdMap = Record<string, string>;

export class IdCache {
  private idMap: {
    Card: IdMap;
    Board: IdMap;
    Workspace: IdMap;
  };

  private ariMap: {
    Card: IdMap;
    Board: IdMap;
    Workspace: IdMap;
  };

  constructor() {
    this.idMap = {
      Card: {},
      Board: {},
      Workspace: {},
    };
    this.ariMap = {
      Card: {},
      Board: {},
      Workspace: {},
    };
  }

  getId(typeName: IdCacheType, shortLinkOrName: string): string | undefined {
    if (checkId(shortLinkOrName)) {
      return shortLinkOrName;
    }
    return this.idMap[typeName][shortLinkOrName];
  }

  setId(typeName: IdCacheType, shortLinkOrName: string, id: string): string {
    if (!checkId(id)) {
      throw new Error(`Not an id: ${id}`);
    }
    if (typeName !== 'Workspace' && !isShortLink(shortLinkOrName)) {
      throw new Error(`Not a shortLink: ${shortLinkOrName}`);
    }
    return (this.idMap[typeName][shortLinkOrName] = id);
  }

  deleteId(typeName: IdCacheType, shortLinkOrName: string): void {
    if (this.getId(typeName, shortLinkOrName)) {
      delete this.idMap[typeName][shortLinkOrName];
    }
  }

  getAri(typeName: IdCacheType, shortLink: string): string | undefined {
    if (checkAri(shortLink)) {
      return shortLink;
    }
    return this.ariMap[typeName][shortLink];
  }

  setAri(typeName: IdCacheType, shortLinkOrName: string, ari: string): string {
    if (!checkAri(ari)) {
      throw new Error(`Not an ARI: ${ari}`);
    }
    if (typeName !== 'Workspace' && !isShortLink(shortLinkOrName)) {
      throw new Error(`Not a shortLink: ${shortLinkOrName}`);
    }
    return (this.ariMap[typeName][shortLinkOrName] = ari);
  }

  setCardId(shortLink: string, id: string) {
    return this.setId('Card', shortLink, id);
  }

  setBoardId(shortLink: string, id: string) {
    return this.setId('Board', shortLink, id);
  }

  setWorkspaceId(name: string, id: string) {
    return this.setId('Workspace', name, id);
  }

  getCardId(shortLink: string) {
    return this.getId('Card', shortLink);
  }

  getBoardId(shortLink: string) {
    return this.getId('Board', shortLink);
  }

  getWorkspaceId(name: string) {
    return this.getId('Workspace', name);
  }

  setCardAri(shortLink: string, ari: string) {
    return this.setAri('Card', shortLink, ari);
  }

  setBoardAri(shortLink: string, ari: string) {
    return this.setAri('Board', shortLink, ari);
  }

  setWorkspaceAri(name: string, ari: string) {
    return this.setAri('Workspace', name, ari);
  }

  getCardAri(shortLink: string) {
    return this.getAri('Card', shortLink);
  }

  getBoardAri(shortLink: string) {
    return this.getAri('Board', shortLink);
  }

  getWorkspaceAri(name: string) {
    return this.getAri('Workspace', name);
  }

  // This is temporary solution to make id conversions for early adoption of the native GraphQL
  #reverse(map: { [key: string]: string }): { [key: string]: string } {
    return Object.keys(map).reduce<{ [key: string]: string }>((ret, key) => {
      ret[map[key]] = key;
      return ret;
    }, {});
  }

  /**
   * 🚧 DO NOT USE 🚧
   * This is a temporary hack!
   * Retrieves shortLink by board id for native quickload request.
   *
   * @param id object Id of the legacy client-side/Backbone Board
   * @returns board shortLink
   */
  __getBoardShortLinkById__DO_NOT_USE(id: string) {
    if (!checkId(id)) {
      return undefined;
    }
    const reverseBoardMap = this.#reverse(this.idMap['Board']);
    return reverseBoardMap[id];
  }
}
