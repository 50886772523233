import type { OperationDefinitionNode } from 'graphql';

const getTrelloNode = (operationNode: OperationDefinitionNode) => {
  if (
    operationNode.selectionSet.selections[0].kind === 'Field' &&
    operationNode.selectionSet.selections[0].name.value === 'trello'
  ) {
    return operationNode.selectionSet.selections[0];
  }
  return undefined;
};

export const getRootNodeForNativeQuery = (
  operationNode: OperationDefinitionNode | undefined,
) => {
  if (operationNode) {
    const trelloNode = getTrelloNode(operationNode);
    if (trelloNode?.selectionSet?.selections?.[0].kind === 'Field') {
      return trelloNode?.selectionSet?.selections?.[0];
    }
  }
  return undefined;
};
