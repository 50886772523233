import { Suspense } from 'react';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { overlayState } from '@trello/nachos/overlay';
import { useSharedState } from '@trello/shared-state';
import { useLazyComponent } from '@trello/use-lazy-component';

import { LazyEndOfTrialFrictionDialog } from 'app/src/components/EndOfTrialFriction/EndOfTrialFrictionDialog/LazyEndOfTrialFrictionDialog';
import type { OnCloseEvent } from 'app/src/components/FreeTrial';
import { LazyPremiumTrialModalOverlay } from 'app/src/components/PremiumTrialModalOverlay/LazyPremiumTrialModalOverlay';
import { LazyPremiumTrialPaymentModalOverlay } from 'app/src/components/PremiumTrialPaymentModalOverlay/LazyPremiumTrialPaymentModalOverlay';

export const Overlays = () => {
  let component = null;

  const PlanSelectionOverlayConnected = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "plan-selection-overlay" */ 'app/src/components/FreeTrial'
      ),
    {
      namedImport: 'PlanSelectionOverlayConnected',
      preload: false,
    },
  );
  const AtlassianAccountMigrationStageOverlays = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "atlassian-account-migration" */ 'app/src/components/AtlassianAccountMigrationStage'
      ),
    {
      namedImport: 'AtlassianAccountMigrationStageOverlays',
      preload: false,
    },
  );

  const [overlay, setOverlayState] = useSharedState(overlayState);

  if (overlay.overlayType === 'plan-selection') {
    component = overlay.context?.orgId && (
      <PlanSelectionOverlayConnected
        orgId={overlay.context?.orgId}
        startFreeTrialOptions={{ redirect: overlay.context?.redirect }}
        onClose={(event: OnCloseEvent) => {
          overlay.context.callback?.(event);

          setOverlayState({
            overlayType: null,
            context: {},
          });
        }}
        boardLeftHandNavButtonClicked={
          overlay.context.boardLeftHandNavButtonClicked
        }
      />
    );
  }

  return (
    <Suspense fallback={null}>
      <ChunkLoadErrorBoundary fallback={null}>
        {component}
        <AtlassianAccountMigrationStageOverlays />
        <LazyPremiumTrialModalOverlay />
        <LazyPremiumTrialPaymentModalOverlay />
        <LazyEndOfTrialFrictionDialog />
      </ChunkLoadErrorBoundary>
    </Suspense>
  );
};
