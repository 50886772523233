/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { cloneElement, Component } from 'react';
import BluebirdPromise from 'bluebird';
import _ from 'underscore';

import type { PopoverTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import { recupWithHelpers } from 'app/scripts/views/internal/recupWithHelpers';
const t = recupWithHelpers('popover');

interface PopOverReact {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _titlePromise: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isStillMounted: any;
}

class PopOverReact extends Component {
  static initClass() {
    // @ts-expect-error TS(2339): Property 'displayName' does not exist on type 'Pop... Remove this comment to see the full error message
    this.prototype.displayName = 'Popover';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.prototype.render = t.renderable(function (this: any) {
      let { children } = this.props;
      const { hideHeader, onBack, onClose } = this.props;

      return t.div({ class: t.classify({ 'no-back': !onBack }) }, () => {
        if (!hideHeader) {
          t.div('.pop-over-header.js-pop-over-header', () => {
            if (onBack != null) {
              t.a('.pop-over-header-back-btn.icon-sm.icon-back.is-shown', {
                onClick: onBack,
                href: '#',
              });
            }

            t.span('.pop-over-header-title', () => {
              if (this.props.hasSafeViewTitle) {
                return t.raw(this.state.title);
              } else {
                return t.text(this.state.title);
              }
            });

            return t.a('.pop-over-header-close-btn.icon-sm.icon-close', {
              onClick: onClose,
              href: '#',
              'data-testid': getTestId<PopoverTestIds>('popover-close'),
            });
          });
        }

        t.div(() => {
          const props = {
            className:
              'pop-over-content js-pop-over-content u-fancy-scrollbar js-tab-parent',
          };

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const addClass = (addedClasses: any, classNames: any) =>
            _.chain(classNames != null ? classNames.split(' ') : undefined)
              .union(addedClasses.split(' '))
              .value()
              .join(' ');

          const addClassesToReactElement = (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            classNames: any,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            reactElement: any,
          ) =>
            cloneElement(reactElement, {
              className: addClass(
                'js-popover-pushed hide',
                reactElement.props.classNames,
              ),
            });

          children = _.chain(children)
            .initial()
            .map((el) => addClassesToReactElement('js-popover-pushed hide', el))
            .value()
            // @ts-expect-error TS(2769): No overload matches this call.
            .concat(_.last(children));

          return t.addElement(<div {...props}>{children}</div>);
        });
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);

    this.state = {
      title: '',
    };
  }

  componentDidMount() {
    this.isStillMounted = true;
    return this.updateTitle(this.props);
  }

  componentDidUpdate() {
    if (this._titlePromise != null) {
      this._titlePromise.cancel();
    }

    return this.updateTitle(this.props);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateTitle(props: any) {
    // mod-no-header popovers don't have titles

    if (props.getViewTitle == null) {
      return;
    }

    // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (props.getViewTitle() === this.state.title) {
      return;
    }

    return (this._titlePromise = BluebirdPromise.resolve(props.getViewTitle())
      // @ts-expect-error TS(2339): Property 'cancellable' does not exist on type 'Blu... Remove this comment to see the full error message
      .cancellable()
      .then((title = '') => {
        if (this.isStillMounted) {
          return this.setState({ title });
        }
      })
      .catch(BluebirdPromise.CancellationError, function () {}));
  }

  componentWillUnmount() {
    this.isStillMounted = false;
    return this._titlePromise != null ? this._titlePromise.cancel() : undefined;
  }
}

PopOverReact.initClass();
export { PopOverReact };
