import { makeErrorEnum } from '@trello/error-handling';

/* eslint-disable-next-line @trello/no-module-logic */
export const DisplayBoardError = makeErrorEnum('DisplayBoard', [
  'ConfirmToView',
  'CardNotFound',
  'CardNotFoundOnThisBoard',
  'BoardNotFound',
  'ServerError',
]);
