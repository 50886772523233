import { SharedState } from '@trello/shared-state';

interface ViewBoardTaskStateStopped {
  traceId: null;
  status: 'stopped';
  error: null;
}
interface ViewBoardTaskStateStarted {
  traceId: string;
  status: 'started';
  error: null;
}
interface ViewBoardTaskStatCompleted {
  traceId: string;
  status: 'completed';
  error: null;
}
interface ViewBoardTaskStateFailed {
  traceId: string;
  status: 'failed';
  error: string | Error;
}

export const viewBoardTaskState = new SharedState<
  | ViewBoardTaskStateStopped
  | ViewBoardTaskStateStarted
  | ViewBoardTaskStatCompleted
  | ViewBoardTaskStateFailed
>({
  traceId: null,
  status: 'stopped',
  error: null,
});
