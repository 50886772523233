import { useCallback, useEffect, useState } from 'react';

import { TrelloStorage } from './StorageProxy';

export const usePersistedState = <T extends string | number | boolean | object>(
  key: string,
  initialValue: T | null,
): [T | null, (newValue: T | null) => void] => {
  const [value, setValue] = useState<T | null>(initialValue);

  const updater = useCallback(
    (newValue: T | null) => {
      setValue(newValue);
      if (newValue === null) {
        TrelloStorage.unset(key);
      } else {
        TrelloStorage.set(key, newValue);
      }
    },
    [key],
  );

  useEffect(() => {
    const storedValue = TrelloStorage.get(key);
    setValue(storedValue ?? value);
    // eslint-disable-next-line @trello/exhaustive-deps
  }, []);

  return [value, updater];
};
