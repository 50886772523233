import { SharedState } from '@trello/shared-state';

// eslint-disable-next-line no-restricted-syntax
export enum WebSocketState {
  CLOSED = 'closed',
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  DISCONNECTED = 'disconnected',
  FORCE_DISCONNECTED = 'force_disconnected',
  TOO_FAR_BEHIND = 'too_far_behind',
  WAITING_TO_RECONNECT = 'waiting_to_reconnect',
  RATE_LIMITED = 'rate_limited',
  RECONNECTIONS_BLOCKED = 'reconnections_blocked',
}

/**
 * Shared state that broadcasts the current ws state
 */
export const webSocketState = new SharedState<WebSocketState>(
  WebSocketState.DISCONNECTED,
);
