// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QIUmf9rrTzMUV2 {
  display: flex;
  position: fixed;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: rgba(0, 0, 0, 0.75);
  scrollbar-gutter: stable;
}
@media print {
  .QIUmf9rrTzMUV2 {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    max-height: 100% !important;
    overflow: scroll !important;
  }
}
.dR7_v9XBQKTza3 {
  position: relative;
  width: auto;
  margin: 48px 0;
  background: transparent;
}
.dR7_v9XBQKTza3:focus {
  outline: none;
}
@media print {
  .dR7_v9XBQKTza3 {
    width: 100%;
    margin: 0;
  }
}
.ySQIVFZtJ_1G5J {
  align-items: flex-start;
}
.QcK0b6bt9yGCnk {
  align-items: center;
}
@media only screen and (max-width: 750px) {
  .dR7_v9XBQKTza3 {
    width: 100%;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `QIUmf9rrTzMUV2`,
	"contents": `dR7_v9XBQKTza3`,
	"alignTop": `ySQIVFZtJ_1G5J`,
	"alignCenter": `QcK0b6bt9yGCnk`
};
export default ___CSS_LOADER_EXPORT___;
