import type { PIIString } from '@trello/privacy';
import { dangerouslyConvertPrivacyString } from '@trello/privacy';

import { escapeForRegex } from './escapeForRegex';

export type Matcher = (input: string | PIIString) => boolean;

export const buildFuzzyMatcher = (query: string | PIIString): Matcher => {
  const searchExpressions: RegExp[] = (
    typeof query === 'string' ? query : dangerouslyConvertPrivacyString(query)
  )
    .split(/\s+/)
    .map((word) => new RegExp(escapeForRegex(word), 'i'));

  return (input: string | PIIString) =>
    searchExpressions.every((searchExpression) =>
      searchExpression.test(
        typeof input === 'string'
          ? input
          : dangerouslyConvertPrivacyString(input),
      ),
    );
};
