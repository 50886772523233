import type { UnbindFn } from 'bind-event-listener';
import { bindAll } from 'bind-event-listener';

import {
  PORTAL_MOUNT_EVENT,
  PORTAL_UNMOUNT_EVENT,
  type PortalEvent,
} from '@atlaskit/portal';

export type { UnbindFn } from 'bind-event-listener';

// note: addAkPortalEventListener listens to ak portal mount and unmount events (see https://atlassian.design/components/portal/examples)
// it is useful to let other global click event listeners ignore clicks on ak portals

export const activeAkPortals = new Set<PortalEvent['detail']['layer']>();

export const addAkPortalEventListener = (
  {
    eventTarget,
  }: {
    eventTarget: EventTarget;
  } = { eventTarget: window },
) => {
  const portalEventListener = ((event: PortalEvent) => {
    const { type, detail } = event;
    // note: currently filtering spotlights only, but could be generalized if
    // we have other use cases with other layer types (like flag, dialog...)
    if (detail?.layer === 'spotlight') {
      if (type === PORTAL_MOUNT_EVENT) {
        activeAkPortals.add('spotlight');
      } else if (type === PORTAL_UNMOUNT_EVENT) {
        activeAkPortals.delete('spotlight');
      }
    }
  }) as EventListener;

  const unbind: UnbindFn = bindAll(eventTarget, [
    {
      type: PORTAL_MOUNT_EVENT,
      listener: portalEventListener,
    },
    {
      type: PORTAL_UNMOUNT_EVENT,
      listener: portalEventListener,
    },
  ]);

  return unbind;
};
