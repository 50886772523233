import { useEffect, useState } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { Analytics } from '@trello/atlassian-analytics';
import { useFeatureFlag } from '@trello/feature-flag-client';

import type { FeatureGateKeys } from './data/featureGates';
import { useFeatureGateWithRefresh } from './useFeatureGateWithRefresh';
import { useIsFeatureGateClientInitializeCompleted } from './useIsFeatureGateClientInitializeCompleted';

export const useFeatureGate = (featureGateName: FeatureGateKeys) => {
  // eslint-disable-next-line @trello/no-feature-flag -- LD flags are deprecated. See http://go/bye-feature-flags.
  const isFeatureGatesRefresherEnabled = useFeatureFlag(
    'xf.statsig-feature-gates-refresher_rgroe',
    false,
  );

  const isFeatureGateClientInitializeCompleted =
    useIsFeatureGateClientInitializeCompleted();

  const [{ loading, value }, setValue] = useState<{
    loading: boolean;
    value?: boolean;
  }>({
    loading: true,
    value: undefined,
  });

  useEffect(() => {
    if (
      !isFeatureGatesRefresherEnabled &&
      isFeatureGateClientInitializeCompleted &&
      value === undefined
    ) {
      const evaluatedGateValue = FeatureGates.checkGate(featureGateName);
      Analytics.setFlagEvaluation(featureGateName, evaluatedGateValue);
      setValue({
        loading: false,
        value: evaluatedGateValue,
      });
    }
  }, [
    isFeatureGatesRefresherEnabled,
    value,
    featureGateName,
    isFeatureGateClientInitializeCompleted,
  ]);

  const gatesWithRefresh = useFeatureGateWithRefresh(featureGateName);

  return isFeatureGatesRefresherEnabled
    ? gatesWithRefresh
    : {
        loading,
        value,
      };
};
