import type { FunctionComponent } from 'react';
import { useCallback } from 'react';

import { Analytics } from '@trello/atlassian-analytics';
import { SearchIcon } from '@trello/nachos/icons/search';
import type { HeaderTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import { getSearchUrl } from '@trello/urls';

import { HeaderLink } from './HeaderLink';

import styles from './HeaderSearchButton.less';

export const HeaderSearchButton: FunctionComponent = () => {
  const onClick = useCallback(() => {
    Analytics.sendClickedButtonEvent({
      buttonName: 'HeaderSearchButton',
      source: 'appHeader',
    });
  }, []);
  return (
    <span className={styles.searchButton}>
      <HeaderLink
        className={styles.searchIcon}
        href={getSearchUrl()}
        onClick={onClick}
        testId={getTestId<HeaderTestIds>('header-search-button')}
      >
        <SearchIcon color="currentColor" size="medium" />
      </HeaderLink>
    </span>
  );
};
