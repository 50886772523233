import { usePremiumFeaturesFragment } from './PremiumFeaturesFragment.generated';
import type { PremiumFeature } from './types';

export const useHasPremiumFeature = (
  boardId: string,
  feature: PremiumFeature,
) => {
  const { data } = usePremiumFeaturesFragment({ from: { id: boardId } });
  if (!data?.premiumFeatures?.length) {
    return false;
  }
  return data.premiumFeatures.includes(feature);
};
