import type { TrelloWindow } from '@trello/window-types';

interface StarredBoard {
  id: string;
  name: string;
  url: string;
  pos: number;
  prefs: {
    backgroundImage: string;
    backgroundColor: string;
  };
}

type StarredBoardsPayload = StarredBoard[];

interface UrlsPayload {
  boardsPage?: string;
  myCardsPage?: string;
}

interface ThemePayload {
  colorMode: 'light' | 'dark' | 'auto' | null;
  effectiveColorMode: 'light' | 'dark' | null;
}

type Unsubscribe = () => void;

interface InvokeChannel {
  prompt: (message: string, defaultValue: string) => string;
}

type OnChannel = object;

interface SendChannel {
  'starred-boards': (boards: StarredBoardsPayload) => void;
  urls: (urls: UrlsPayload) => void;
  theme: (theme: ThemePayload) => void;
  'set-notification-count': (count: number) => void;
}

type SendSyncChannel = object;

interface DesktopIpc {
  isChannelSupported<
    Key extends keyof (InvokeChannel &
      OnChannel &
      SendChannel &
      SendSyncChannel),
  >(
    channel: Key,
  ): boolean;
  invoke<Key extends keyof InvokeChannel>(
    channel: Key,
    ...args: Parameters<InvokeChannel[Key]>
  ): Promise<ReturnType<InvokeChannel[Key]>>;
  on<Key extends keyof OnChannel>(
    channel: Key,
    listener: OnChannel[Key],
  ): Unsubscribe;
  once<Key extends keyof OnChannel>(
    channel: Key,
    listener: OnChannel[Key],
  ): Unsubscribe;
  removeAllListeners<Key extends keyof OnChannel>(channel: Key): void;
  send<Key extends keyof SendChannel>(
    channel: Key,
    ...args: Parameters<SendChannel[Key]>
  ): void;
  sendSync<Key extends keyof SendSyncChannel>(
    channel: Key,
    ...args: Parameters<SendSyncChannel[Key]>
  ): ReturnType<SendSyncChannel[Key]>;
}

declare const window: TrelloWindow;

export const desktopIpc = window?.electron?.ipc as DesktopIpc;
export type { StarredBoard, StarredBoardsPayload };
