import * as Types from '@trello/graphql/generated';

import { useQuickLoad } from '@trello/quickload';
import type { TrelloQueryHookOptions, TrelloLazyQueryHookOptions, TrelloSuspenseQueryHookOptions } from '@trello/quickload';
import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const IsAdminOfAnyEnterpriseDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"IsAdminOfAnyEnterprise"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"member"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"idEnterprise"}},{"kind":"Field","name":{"kind":"Name","value":"idEnterprisesAdmin"}},{"kind":"Field","name":{"kind":"Name","value":"idPremOrgsAdmin"}},{"kind":"Field","name":{"kind":"Name","value":"organizations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"idEnterprise"}}]}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"IsAdminOfAnyEnterprise","document":IsAdminOfAnyEnterpriseDocument}} as const;
export type IsAdminOfAnyEnterpriseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IsAdminOfAnyEnterpriseQuery = (
  { __typename: 'Query' }
  & { member?: Types.Maybe<(
    { __typename: 'Member' }
    & Pick<Types.Member, 'id' | 'idEnterprise' | 'idEnterprisesAdmin' | 'idPremOrgsAdmin'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Types.Organization, 'id' | 'idEnterprise'>
    )> }
  )> }
);

/**
 * __useIsAdminOfAnyEnterpriseQuery__
 *
 * To run a query within a React component, call `useIsAdminOfAnyEnterpriseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAdminOfAnyEnterpriseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAdminOfAnyEnterpriseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsAdminOfAnyEnterpriseQuery(
  baseOptions: TrelloQueryHookOptions<
    IsAdminOfAnyEnterpriseQuery,
    IsAdminOfAnyEnterpriseQueryVariables
  > &
    (
      | { variables: IsAdminOfAnyEnterpriseQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: IsAdminOfAnyEnterpriseDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  const result = Apollo.useQuery<
    IsAdminOfAnyEnterpriseQuery,
    IsAdminOfAnyEnterpriseQueryVariables
  >(IsAdminOfAnyEnterpriseDocument, options);
  // reconstruct result because modifying the useQuery result actually changes apollo behavior because of memoization
  const trelloResult = { ...result };
  if (isQuickLoading) {
    trelloResult.loading = true;
  }

  return trelloResult;
}
export function useIsAdminOfAnyEnterpriseLazyQuery(
  baseOptions?: TrelloLazyQueryHookOptions<
    IsAdminOfAnyEnterpriseQuery,
    IsAdminOfAnyEnterpriseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsAdminOfAnyEnterpriseQuery,
    IsAdminOfAnyEnterpriseQueryVariables
  >(IsAdminOfAnyEnterpriseDocument, options);
}
export function useIsAdminOfAnyEnterpriseSuspenseQuery(
  baseOptions?: TrelloSuspenseQueryHookOptions<
    IsAdminOfAnyEnterpriseQuery,
    IsAdminOfAnyEnterpriseQueryVariables
  >,
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: IsAdminOfAnyEnterpriseDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  return Apollo.useSuspenseQuery<
    IsAdminOfAnyEnterpriseQuery,
    IsAdminOfAnyEnterpriseQueryVariables
  >(IsAdminOfAnyEnterpriseDocument, options);
}
export type IsAdminOfAnyEnterpriseQueryHookResult = ReturnType<
  typeof useIsAdminOfAnyEnterpriseQuery
>;
export type IsAdminOfAnyEnterpriseLazyQueryHookResult = ReturnType<
  typeof useIsAdminOfAnyEnterpriseLazyQuery
>;
export type IsAdminOfAnyEnterpriseSuspenseQueryHookResult = ReturnType<
  typeof useIsAdminOfAnyEnterpriseSuspenseQuery
>;
export type IsAdminOfAnyEnterpriseQueryResult = Apollo.QueryResult<
  IsAdminOfAnyEnterpriseQuery,
  IsAdminOfAnyEnterpriseQueryVariables
>;
