import { useEffect, useMemo, useRef, useState } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { SourceType } from '@trello/atlassian-analytics';
import { Analytics } from '@trello/atlassian-analytics';
import { useFeatureFlag } from '@trello/feature-flag-client';
import { deepEqual } from '@trello/objects';
import { useSharedState } from '@trello/shared-state';

import type {
  ExperimentVariations,
  FeatureExperimentKeys,
  FeatureExperimentParameters,
} from './data/featureGates';
import { featureGateClientInitializationState } from './featureGateClientInitializationState';

export const useFireExposureEvent = <T extends FeatureExperimentKeys>({
  experimentName,
  value,
  fireExposureEvent,
  options = { isLayer: false },
}: {
  experimentName: T;
  value: ExperimentVariations<T, FeatureExperimentParameters<T>>;
  fireExposureEvent?: boolean;
  options?: {
    isLayer: boolean;
  };
}) => {
  const [prevExposureFiredValue, setPrevExposureFiredValue] =
    useState<typeof value>('not-enrolled');

  // eslint-disable-next-line @trello/no-feature-flag -- LD flags are deprecated. See http://go/bye-feature-flags.
  const isFeatureGatesRefresherEnabled = useFeatureFlag(
    'xf.statsig-feature-gates-refresher_rgroe',
    false,
  );
  const [featureGateClientInitState] = useSharedState(
    featureGateClientInitializationState,
  );

  const currentInitArgs = useMemo(
    () => ({
      identifiers: featureGateClientInitState.identifiers,
      customAttributes: featureGateClientInitState.customAttributes,
    }),
    [
      featureGateClientInitState.customAttributes,
      featureGateClientInitState.identifiers,
    ],
  );
  const prevInitArgs = useRef<typeof currentInitArgs>({
    identifiers: undefined,
    customAttributes: undefined,
  });

  useEffect(() => {
    if (
      featureGateClientInitState.isInitialized &&
      isFeatureGatesRefresherEnabled &&
      fireExposureEvent &&
      (prevExposureFiredValue !== value ||
        !deepEqual(prevInitArgs.current, currentInitArgs))
    ) {
      prevInitArgs.current = currentInitArgs;
      if (options.isLayer) {
        FeatureGates.manuallyLogLayerExposure(experimentName, value);
      } else {
        FeatureGates.manuallyLogExperimentExposure(experimentName);
      }
      Analytics.sendTrackEvent({
        action: 'exposed',
        actionSubject: 'feature',
        source: 'trello' as SourceType,
        attributes: {
          experimentName,
          value,
        },
      });
      setPrevExposureFiredValue(value);
    }
  }, [
    currentInitArgs,
    experimentName,
    featureGateClientInitState.isInitialized,
    fireExposureEvent,
    isFeatureGatesRefresherEnabled,
    options.isLayer,
    prevExposureFiredValue,
    value,
  ]);
};
