import type {
  ClientOptions,
  CustomAttributes,
  Identifiers,
} from '@atlaskit/feature-gate-js-client';
import FeatureGates, {
  FeatureGateEnvironment,
} from '@atlaskit/feature-gate-js-client';
import { Analytics } from '@trello/atlassian-analytics';
import { getAaId, getMemberId } from '@trello/authentication';
import { atlassianFeatureFlagClientKey, environment } from '@trello/config';
import { getScreenFromUrl } from '@trello/marketing-screens';
import { deepEqual } from '@trello/objects';

import { updateAllSharedStateFeatureGatesAndExperiments } from './state/updateAllSharedStateFeatureGatesAndExperiments';
import { featureGateClientInitializationState } from './featureGateClientInitializationState';

function getFeatureGateEnvironment() {
  let clientEnv = FeatureGateEnvironment.Development;
  if (environment === 'staging') {
    clientEnv = FeatureGateEnvironment.Staging;
  } else if (environment === 'prod') {
    clientEnv = FeatureGateEnvironment.Production;
  }

  return clientEnv;
}

let initPromise: Promise<void> | undefined;

const previousInitArgs = {
  identifiers: {},
  customAttributes: {},
};

const dedupedInit = async (
  fetchOptions: ClientOptions,
  identifiers: Identifiers,
  customAttributes: CustomAttributes,
) => {
  if (!FeatureGates.initializeCalled()) {
    previousInitArgs.identifiers = identifiers;
    previousInitArgs.customAttributes = customAttributes;

    initPromise = FeatureGates.initialize(
      fetchOptions,
      identifiers,
      customAttributes,
    );
    await initPromise;
  } else {
    // Check that the new args are different from the previous ones
    // before invoking FeatureGates.updateUser to avoid redundant calls.
    // This optimization, however, is not a guarantee that unique calls will be made at most once
    if (!deepEqual(previousInitArgs, { identifiers, customAttributes })) {
      previousInitArgs.identifiers = identifiers;
      previousInitArgs.customAttributes = customAttributes;

      await FeatureGates.updateUser(
        fetchOptions,
        identifiers,
        customAttributes,
      );
    } else {
      // If the invocation is deemed redundant, we still need to wait for the initial init to resolve
      await initPromise;
    }
  }
};

export async function initFeatureGateClient({
  workspaceId,
  customAttributes,
}: {
  customAttributes: CustomAttributes;
  workspaceId: string | null;
}) {
  const fetchOptions: ClientOptions = {
    apiKey: atlassianFeatureFlagClientKey,
    environment: getFeatureGateEnvironment(),
    targetApp: 'trello_web',
    useGatewayURL: false,
  };

  const identifiers = {
    trelloUserId: getMemberId() || undefined,
    trelloWorkspaceId: workspaceId || undefined,
    atlassianAccountId: getAaId() || undefined,
    analyticsAnonymousId:
      Analytics?.analytics?.getAnonymousId() ?? 'unidentified',
  };

  try {
    await dedupedInit(fetchOptions, identifiers, customAttributes);
    // Populating client initialization status for feature gate consumer hooks
    featureGateClientInitializationState.setValue({
      isInitialized: true,
      identifiers,
      customAttributes,
    });

    // Hydrate shared state with feature gates and experiments
    updateAllSharedStateFeatureGatesAndExperiments();
  } catch (error) {
    if (error instanceof Error) {
      // Ignore set of request abort errors
      if (
        !/The user aborted a request\.|Fetch is aborted|The operation was aborted\./.test(
          error.message,
        )
      ) {
        const source = getScreenFromUrl();
        Analytics.sendOperationalEvent({
          action: 'errored',
          actionSubject: 'featureGateClient',
          source,
          attributes: {
            identifiers,
            customAttributes,
            error: error.message,
          },
        });
      }
    }
  }
}
