import { RouteId } from './routes';

const organizationRoutes = new Set<RouteId>([
  RouteId.WORKSPACE_VIEW,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.ACCOUNT,
  RouteId.WORKSPACE_BILLING,
  RouteId.BILLING,
  RouteId.MEMBER_CARDS_FOR_ORG,
  RouteId.ORGANIZATION_BOARDS,
  RouteId.OLD_ORGANIZATION_GUESTS,
  RouteId.OLD_ORGANIZATION_REQUESTS,
  RouteId.OLD_ORGANIZATION_MEMBERS,
  RouteId.ORGANIZATION_BY_ID,
  RouteId.ORGANIZATION_EXPORT,
  RouteId.ORGANIZATION_FREE_TRIAL,
  RouteId.OLD_ORGANIZATION_FREE_TRIAL,
  RouteId.ORGANIZATION_GUESTS,
  RouteId.ORGANIZATION_REQUESTS,
  RouteId.ORGANIZATION_MEMBER_CARDS,
  RouteId.ORGANIZATION_MEMBERS,
  RouteId.ORGANIZATION_POWER_UPS,
  RouteId.OLD_ORGANIZATION_POWER_UPS,
  RouteId.ORGANIZATION_TABLES,
  RouteId.OLD_ORGANIZATION_TABLES,
  RouteId.PROFILE,
  RouteId.USER_OR_ORG,
  RouteId.MEMBER_HOME_WORKSPACE_BOARDS,
  RouteId.ORGANIZATION_HIGHLIGHTS,
  RouteId.OLD_ORGANIZATION_GETTING_STARTED,
]);

export const isOrganizationRoute = (routeId: RouteId): boolean =>
  organizationRoutes.has(routeId);
