import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { CardNumberElement } from '@stripe/react-stripe-js';
import type { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';

import { forTemplate } from '@trello/legacy-i18n';
import type { PurchaseFormIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import { useGlobalTheme } from '@trello/theme';

import { getDefaultIframeOptions } from './getDefaultIframeOptions';
import { Label } from './Label';
import { ValidationError } from './ValidationError';

import styles from './ADSCreditCardNumber.less';

const format = forTemplate('credit_card');

interface CreditCardNumberInputProps {
  isInvalid?: boolean;
  isDisabled?: boolean;
  onChange?: (event: StripeCardNumberElementChangeEvent) => void;
  errorMessage?: string | null;
}

export const ADSCreditCardNumber: FunctionComponent<
  CreditCardNumberInputProps
> = ({ errorMessage, isDisabled, isInvalid, onChange }) => {
  const { effectiveColorMode } = useGlobalTheme();

  const iframeOptions = useMemo(
    () => ({
      ...getDefaultIframeOptions({ isDisabled }),
      disabled: isDisabled,
      placeholder: '4111 1111 1111 1111',
      showIcon: true,
      theme: effectiveColorMode,
    }),
    [isDisabled, effectiveColorMode],
  );

  return (
    <div
      className={styles.container}
      data-testid={getTestId<PurchaseFormIds>('credit-card-number')}
    >
      <Label isRequired>{format('credit-card')}</Label>
      <CardNumberElement options={iframeOptions} onChange={onChange} />
      {isInvalid && errorMessage && (
        <ValidationError
          id={getTestId<PurchaseFormIds>('credit-card-number-validation-error')}
        >
          {errorMessage}
        </ValidationError>
      )}
    </div>
  );
};
