/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import Promise from 'bluebird';
import _ from 'underscore';

import { Analytics, Apdex } from '@trello/atlassian-analytics';
import { isMemberLoggedIn } from '@trello/authentication';
import { browserStr, isDesktop, isTouch, osStr } from '@trello/browser';
import { Cookies } from '@trello/cookies';
import { ApiError, assert } from '@trello/error-handling';
import { checkId, isShortLink } from '@trello/id-cache';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { getScreenFromUrl } from '@trello/marketing-screens';
import { monitorStatus } from '@trello/monitor';
import {
  type BoardViews,
  isActiveRoute,
  RouteId,
  routerState,
} from '@trello/router';
import { importWithRetry } from '@trello/use-lazy-component';

import { currentModelManager } from 'app/scripts/controller/currentModelManager';
import { errorPage } from 'app/scripts/controller/errorPage';
import { Auth } from 'app/scripts/db/Auth';
import { getUpToDateModel } from 'app/scripts/db/getUpToDateModel';
import { ModelLoader } from 'app/scripts/db/model-loader';
import { ModelCache } from 'app/scripts/db/ModelCache';
import { Util } from 'app/scripts/lib/util';
import { ninvoke } from 'app/scripts/lib/util/ninvoke';
import type { Board } from 'app/scripts/models/Board';
import { DisplayBoardError } from 'app/scripts/views/board/displayBoardError';
import { Alerts } from 'app/scripts/views/lib/Alerts';
import { PostRender } from 'app/scripts/views/lib/PostRender';
import { viewBoardTaskState } from 'app/src/components/Board/viewBoardTaskState';
import { getSpinner } from 'app/src/getSpinner';
import type { BoardView } from '../views/board/BoardView';
import { activeBoardPageState } from './activeBoardPageState';
import { controllerEvents } from './controllerEvents';
import type { Controller } from './index';
import { preloadBoardView } from './preloadBoardView';
import { setBoardLocation } from './setBoardLocation';

let isFirstBoardPaint = true;
let lastBoardShortLink: string | null = null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractReasonFromApiError = (err: any) =>
  err?.name?.replace(/^API::/, '');

const QUERY_PARAMS_FOR_SETTINGS = new Set([
  'cameFromGettingStarted',
  'filter',
  'inviteMemberId',
  'menu',
  'openCardComposerInFirstList',
  'openListComposer',
  'search_id',
  'showPopover',
  'signature',
  'returnBoardShortLink',
]);

const getRenderLock = function () {
  PostRender.hold();
  return Promise.resolve().disposer(() => PostRender.release());
};

// This should show only if you came from the /w/teamname/getting-started
// view. We are passing a query param for ?cameFromGettingStarted=true
// and evaluating it, which gets passes to args here. We don't want to show
// the popover on refresh of page, so we don't want to evaluate the actual
// query param itself, since the board will redirect to a clean url without it
// after loading.
export function showGettingStartedPupPopover(
  args: { cameFromGettingStarted: boolean },
  boardView: BoardView,
) {
  return Promise.try(() => {
    if (args.cameFromGettingStarted) {
      boardView.renderTeamOnboardingPupPopover();
    }
    return boardView;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function openInvitePopover(args: any, boardView: any) {
  // fire-and-forget, resolve boardView in the meantime
  Promise.try(async () => {
    const board = boardView.model;

    if (
      args.inviteMemberId &&
      (board.attributes.idOrganization || board.attributes.idEnterprise)
    ) {
      const { OpenAutoInviteDialog } = await importWithRetry(
        () =>
          import(
            /* webpackChunkName: "open-auto-invite-dialog" */ './OpenAutoInviteDialog'
          ),
      );

      if (!isDesktop() && !isTouch()) {
        OpenAutoInviteDialog({
          inviteMemberId: args.inviteMemberId,
          signature: args.signature,
          boardId: board.id,
        });
        return;
      }

      boardView.openAddMembers();
      Analytics.sendScreenEvent({
        name: 'requestAccessOpenInviteModalScreen',
        attributes: {
          reason: 'Unauthorized',
          requestorId: args.inviteMemberId,
          shortlink: board.attributes.shortLink,
        },
        containers: {
          card: args.idCard ? { id: args.idCard } : undefined,
          organization: board.attributes.idOrganization
            ? { id: board.attributes.idOrganization }
            : undefined,
          enterprise: board.attributes.idEnterprise
            ? { id: board.attributes.idEnterprise }
            : undefined,
          board: { id: board.id },
        },
      });
    }
  });

  return Promise.resolve(boardView);
}

export function openJoinBoardModal(boardView: BoardView) {
  return Promise.try(() => {
    const board = boardView.model;
    const hasValidInviteToken = Util.hasValidInviteTokenFor(board, Auth.me());

    if (!isMemberLoggedIn() && hasValidInviteToken) {
      boardView.renderJoinBoardModal();
    }

    return boardView;
  });
}

function renderBoard(
  this: typeof Controller,
  board: Board,
  options: {
    section: BoardViews['view'];
    settings: {
      filter: object;
      menu: 'closed' | 'filter' | 'activity';
    };
    referrerUsername?: string | null;
  },
) {
  // Important that we set this root-level class before we load the board
  // assets, to avoid the header color flickering. See also:
  // https://trello.atlassian.net/browse/FEPLAT-1409
  $('#trello-root').addClass('body-board-view');

  return new Promise((resolve, reject) => {
    return preloadBoardView().then(({ BoardView }) => {
      return Promise.try(() => {
        let boardView;
        if (currentModelManager.onBoardView(board.id)) {
          boardView = this.existingTopLevelView(BoardView, board);
          if (_.isEqual(boardView.options.settings, options.settings)) {
            boardView.closeDetailView();

            resolve(boardView);
            return;
          }
        }

        activeBoardPageState.setValue({
          view: options.section,
          boardIdOrShortLink: board.id,
        });
        controllerEvents.trigger('clearPreviousView');
        controllerEvents.trigger('setViewType', board);

        boardView = this.topLevelView(BoardView, board, options);

        $('#content').html(boardView.render().el);
        // the clearPreviousView call above is necessary, but it also removes
        // 'body-board-view' class from the root, which makes the board header
        // blue. We'll re-add it here for now, should be better addressed via
        // https://trello.atlassian.net/browse/FEPLAT-1409
        $('#trello-root').addClass('body-board-view');

        return resolve(boardView);
      });
    });
  });
}

export function settingsFromQuery() {
  return _.chain(location.search.substr(1).split('&'))
    .map((entry) => entry.split('=', 2))
    .filter(function (...args) {
      const [key] = Array.from(args[0]);
      return QUERY_PARAMS_FOR_SETTINGS.has(key);
    })
    .map(function (...args) {
      const [key, value] = Array.from(args[0]);
      return [key, value];
    })
    .object()
    .value();
}

function butlerSettingsFromQuery() {
  const butlerParamMapping = {
    c: 'butlerNewCommand',
    i: 'butlerNewIcon',
    l: 'butlerNewLabel',
    taskId: 'taskId',
    source: 'source',
  };
  const butlerSettings = {};
  const params = new URLSearchParams(location.search);
  Object.keys(butlerParamMapping).forEach((param) => {
    if (params.has(param)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      butlerSettings[butlerParamMapping[param]] = params.get(param);
    }
  });
  return butlerSettings;
}

// Subtract the current time from the hex timestamp in the preload trace
// to account for the additional preload latency
export function getDeltaFromPreloadTraceId(
  preloadedTraceId: string | undefined,
) {
  if (!preloadedTraceId) {
    return undefined;
  }
  return parseInt(preloadedTraceId.slice(0, 8), 16) * 1000 - Date.now();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function displayBoard(this: typeof Controller, settings: any) {
  const { idBoard, idCard, referrerUsername } = settings;
  assert(
    idBoard || idCard,
    'Illegal invocation of displayBoard: requires one of idBoard, or idCard',
  );
  if (
    (idCard != null && !checkId(idCard) && !isShortLink(idCard)) ||
    (idBoard != null && !checkId(idBoard) && !isShortLink(idBoard))
  ) {
    return Promise.resolve(
      errorPage({
        errorType: 'malformedUrl',
      }),
    );
  }

  return Promise.using(getRenderLock(), () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loadCardData = _.once((idCardOrShortLink: any, traceId: any) =>
      ModelLoader.loadCardData(idCardOrShortLink, null, traceId).catch(
        ApiError,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (err: any) => {
          const reason = extractReasonFromApiError(err);
          return Promise.reject(DisplayBoardError.CardNotFound(reason));
        },
      ),
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const awaitView = ({ model }: any) => {
      return new Promise((resolve) => {
        return this.waitForId(model, resolve);
      });
    };

    // Allow settings object values to also be provided via query params
    const queryParams = settingsFromQuery();
    const butlerSettings =
      settings.section === 'butler' ? butlerSettingsFromQuery() : {};
    const detailArgs = {
      loadCardData,
      ...settings,
      ...butlerSettings,
      ...queryParams,
    };

    const startTime = Date.now();
    const apdexTask = idCard != null ? 'card' : 'board';
    const apdexTaskId = idCard != null ? idCard : idBoard;
    // Will get a traceId if the app has preloaded any relevant routes.
    const preloadedTraceId = ModelLoader.getPreloadTraceId();
    const start = Date.now();
    const preloadDelta = getDeltaFromPreloadTraceId(preloadedTraceId);
    const section = detailArgs.section ?? 'board';
    const isBoardCached = !!getUpToDateModel('Board', idBoard);

    const currentBoardShortLink = isActiveRoute(
      routerState.value,
      RouteId.BOARD,
    )
      ? routerState.value.params.shortLink
      : null;
    const alreadyRenderedBoard = lastBoardShortLink === currentBoardShortLink;
    lastBoardShortLink = currentBoardShortLink;
    let boardId: string | null = null;
    let loadTime = 0;
    let renderTime = 0;

    const stableIsFirstBoardPaint = isFirstBoardPaint;
    isFirstBoardPaint = false;

    const sharedAttributes = () => ({
      browser: browserStr,
      os: osStr,
      isPageActive: monitorStatus.value === 'active',
      view: section,
      boardShortLink: idBoard,
      boardId,
      isFirstBoardPaint: stableIsFirstBoardPaint,
      isInitialLoad: !!preloadDelta,
      renderTime,
      loadTime,
      timeFromPageStartToBoardStart: preloadDelta
        ? Math.abs(preloadDelta)
        : null,
      isBoardCached,
    });

    const traceId = Analytics.startTask({
      taskName: 'view-board',
      source: getScreenFromUrl(),
      traceId: preloadedTraceId,
      attributes: {
        ...sharedAttributes(),
        taskDurationDelta: preloadDelta,
      },
    });
    Apdex.start({
      task: apdexTask,
      taskId: apdexTaskId,
    });
    viewBoardTaskState.setValue({
      status: 'started',
      traceId,
    });

    const unsubscribers: (() => void)[] = [];

    const resetSubscribers = () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
      viewBoardTaskState.reset();
    };

    const taskFailed = (error: Error) => {
      if (viewBoardTaskState.value.status === 'stopped') {
        return;
      }

      renderTime = Date.now() - start - loadTime;
      Apdex.stop({
        task: apdexTask,
        taskId: apdexTaskId,
      });
      Analytics.taskFailed({
        taskName: 'view-board',
        traceId: viewBoardTaskState.value.traceId!,
        source: getScreenFromUrl(),
        error,
        attributes: sharedAttributes(),
      });
      resetSubscribers();
    };

    const taskSucceeded = () => {
      if (viewBoardTaskState.value.status === 'stopped') {
        return;
      }

      renderTime = Date.now() - start - loadTime;
      Apdex.stop({
        task: apdexTask,
        taskId: apdexTaskId,
      });
      Analytics.taskSucceeded({
        taskName: 'view-board',
        traceId,
        source: getScreenFromUrl(),
        attributes: sharedAttributes(),
      });
      resetSubscribers();
    };

    const taskAborted = (error: Error) => {
      if (viewBoardTaskState.value.status === 'stopped') {
        return;
      }

      renderTime = Date.now() - start - loadTime;
      Apdex.stop({
        task: apdexTask,
        taskId: apdexTaskId,
      });
      Analytics.taskAborted({
        taskName: 'view-board',
        traceId,
        source: getScreenFromUrl(),
        error,
        attributes: sharedAttributes(),
      });
      resetSubscribers();
    };

    if (section === 'board' && !alreadyRenderedBoard) {
      const unsubscribeFromTaskState = viewBoardTaskState.subscribe((state) => {
        if (state.status === 'completed') {
          taskSucceeded();
        } else if (state.status === 'failed') {
          taskFailed(
            state.error instanceof Error ? state.error : new Error(state.error),
          );
        }
      });
      const unsubscribeFromMonitorStatus = monitorStatus.subscribe((state) => {
        if (state === 'idle') {
          taskAborted(new Error('User tab went idle'));
        }
      });
      const unsubscribeFromRouteState = routerState.subscribe((state) => {
        if (
          !isActiveRoute(state, RouteId.BOARD) ||
          currentBoardShortLink !== state.params.shortLink
        ) {
          taskAborted(new Error('Navigation to new route'));
        }
      });

      unsubscribers.push(unsubscribeFromTaskState);
      unsubscribers.push(unsubscribeFromMonitorStatus);
      unsubscribers.push(unsubscribeFromRouteState);
    } else if (alreadyRenderedBoard) {
      taskSucceeded();
    }

    return Promise.using(getSpinner(), () => {
      const analyticsActionSubject =
        getUpToDateModel('Board', idBoard) != null ? 'cachedBoard' : 'board';

      return (
        Promise.try<string>(() => {
          if (idCard != null) {
            // If we're showing a card, we always want to load the data for it
            return loadCardData(idCard, traceId).call('get', 'idBoard');
          }
          return idBoard;
        })
          .then<Board[]>((idBoardOrShortLink) => {
            const loadCalendar = settings.section === 'calendar';

            if (loadCalendar) {
              ModelLoader.loadBoardChecklists(idBoardOrShortLink, traceId)
                // It's possible the API request will fail due to there being too
                // many checklists - in this case it's better to at least show them
                // their cards instead of giving them a "Board not found" error
                // @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
                .catch(ApiError, function () {});
            }

            const boardInfoPromise = ModelLoader.loadCurrentBoardInfo(
              idBoardOrShortLink,
              traceId,
            );

            const boardListsCardsPromise =
              ModelLoader.loadCurrentBoardListsCards(
                idBoardOrShortLink,
                traceId,
              );

            return (
              Promise.all([boardInfoPromise, boardListsCardsPromise])
                .catch(ApiError.Unconfirmed, () =>
                  Promise.reject(
                    DisplayBoardError.ConfirmToView('Unconfirmed'),
                  ),
                )
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch(ApiError.Server, (err: any) =>
                  Promise.reject(DisplayBoardError.ServerError(err.message)),
                )
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch(ApiError, (err: any) => {
                  const reason = extractReasonFromApiError(err);
                  return Promise.reject(
                    DisplayBoardError.BoardNotFound(reason),
                  );
                })
            );
          })
          .then((boards) => {
            // By the time the Promise.all resolves, the boards will be identical
            // and we can just pick the first one.
            const board = boards[0];
            boardId = board.id;
            loadTime = preloadDelta
              ? Date.now() - start + Math.abs(preloadDelta)
              : Date.now() - start;

            if (referrerUsername && board.isTemplate()) {
              Cookies.set(
                'referrer',
                referrerUsername,
                {
                  path: '/',
                  expires: 14,
                },
                'necessary',
              );
            }

            const loadDuration = Date.now() - startTime;
            Analytics.sendOperationalEvent({
              action: 'loaded',
              // @ts-expect-error TS(2322): Type '"board" | "cachedBoard"' is not assignable t... Remove this comment to see the full error message
              actionSubject: analyticsActionSubject,
              containers: {
                board: {
                  id: board.id,
                },
              },
              attributes: {
                loadDuration,
              },
              source: getScreenFromUrl(),
            });

            const renderStartTime = Date.now();

            return (
              renderBoard
                .call(this, board, {
                  section: detailArgs.section,
                  settings: {
                    filter: detailArgs.filter,
                    menu: detailArgs.menu,
                  },
                  referrerUsername,
                })
                .tap(function () {
                  const loadAndRenderDuration = Date.now() - startTime;
                  const renderDuration = Date.now() - renderStartTime;

                  Analytics.sendOperationalEvent({
                    action: 'rendered',
                    // @ts-expect-error TS(2322): Type '"board" | "cachedBoard"' is not assignable t... Remove this comment to see the full error message
                    actionSubject: analyticsActionSubject,
                    containers: {
                      board: {
                        id: board.id,
                      },
                    },
                    attributes: {
                      renderDuration,
                    },
                    source: getScreenFromUrl(),
                  });

                  Analytics.sendOperationalEvent({
                    action: 'displayed', // loadedAndRendered
                    // @ts-expect-error TS(2322): Type '"board" | "cachedBoard"' is not assignable t... Remove this comment to see the full error message
                    actionSubject: analyticsActionSubject,
                    containers: {
                      board: {
                        id: board.id,
                      },
                    },
                    attributes: {
                      loadAndRenderDuration,
                    },
                    source: getScreenFromUrl(),
                  });
                })
                .tap(awaitView)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((boardView: any) => {
                  boardView.showBoardDetailView(detailArgs);
                  showGettingStartedPupPopover(detailArgs, boardView);
                  openInvitePopover(detailArgs, boardView);
                  openJoinBoardModal(boardView);

                  const numCards = board.openCards().length;
                  if (section !== 'board' || numCards === 0) {
                    taskSucceeded();
                  } else if (board.get('closed')) {
                    taskSucceeded();
                  }
                })
            );
          })
          .catch(DisplayBoardError.ConfirmToView, (err) => {
            assert(
              Auth.isLoggedIn(),
              "Got 401: 'Confirm to view' while not logged in",
            );
            taskFailed(err);

            const reason = err.message;
            Analytics.sendScreenEvent({
              // @ts-expect-error TS(2322): Type '"unconfirmedBoardNotFoundScreen"' is not ass... Remove this comment to see the full error message
              name: 'unconfirmedBoardNotFoundScreen',
              attributes: {
                reason,
              },
            });
            return ninvoke(ModelCache, 'waitFor', 'Member', Auth.myId()).then(
              () => {
                return errorPage({
                  errorType: 'unconfirmedBoardNotFound',
                  ...Auth.me().toJSON(),
                });
              },
            );
          })
          .catch(DisplayBoardError.CardNotFound, (err) => {
            taskFailed(err);

            const reason = err.message;
            Analytics.sendScreenEvent({
              // @ts-expect-error TS(2322): Type '"cardNotFoundScreen"' is not assignable to t... Remove this comment to see the full error message
              name: 'cardNotFoundScreen',
              attributes: {
                reason,
              },
            });
            return errorPage({
              errorType: 'cardNotFound',
              reason,
            });
          })
          .catch(DisplayBoardError.BoardNotFound, (err) => {
            taskAborted(err);

            const reason = err.message;
            Analytics.sendScreenEvent({
              // @ts-expect-error TS(2322): Type '"boardNotFoundScreen"' is not assignable to ... Remove this comment to see the full error message
              name: 'boardNotFoundScreen',
              attributes: {
                reason,
              },
            });
            return errorPage({
              errorType: 'boardNotFound',
              reason,
            });
          })
          // eslint-disable-next-line @typescript-eslint/no-shadow
          .catch(DisplayBoardError.CardNotFoundOnThisBoard, ({ idBoard }) => {
            taskFailed(new Error('card does not exist'));

            Alerts.show('card does not exist', 'warning', 'doesnotexist', 5000);
            return setBoardLocation(idBoard, settings.section);
          })
          .catch(DisplayBoardError.ServerError, (err) => {
            taskFailed(err);

            const reason = err.message;
            Analytics.sendScreenEvent({
              name: 'serverErrorScreen',
              attributes: {
                reason,
              },
            });
            return errorPage({
              errorType: 'serverError',
              reason,
            });
          })
          .catch((error) => {
            taskFailed(error);
          })
      );
    });
  });
}

// [LOADCARD]
//
// It's probable that we'll need to do two data loads in order to load the
// board: one to determine what board the card lives on, and another to load
// the card.
//
// It's *possible*, if bizarre QuickLoad race conditions play in our favor,
// that doing the naive thing will just *happen* to work -- if the timing
// is just right, QuickLoad will give us the same response back twice, and
// two calls create only one actual network request.
//
// But. Let's memoize it ourself just in case, so that we don't have to worry
// about QuickLoad's behavior changing and doubling the requests here.
