import { WebSocketState } from './webSocketState';

/**
 * Denotes whether the WebSocketState is currently in a "terminal" state,
 * meaning that it should not be able to transition to another state.
 * @param state WebSocketState
 * @returns Boolean
 */
export function isTerminalWebSocketState(state: WebSocketState): boolean {
  switch (state) {
    case WebSocketState.FORCE_DISCONNECTED:
    case WebSocketState.RATE_LIMITED:
    case WebSocketState.TOO_FAR_BEHIND:
    case WebSocketState.RECONNECTIONS_BLOCKED:
      return true;
    default:
      return false;
  }
}
