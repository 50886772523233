import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type PremiumFeaturesFragment = (
  { __typename: 'Board' }
  & Pick<Types.Board, 'id' | 'premiumFeatures'>
);

export const PremiumFeaturesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PremiumFeatures' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'premiumFeatures' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UsePremiumFeaturesFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      PremiumFeaturesFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UsePremiumFeaturesFragmentResult
  extends Omit<Apollo.UseFragmentResult<PremiumFeaturesFragment>, 'data'> {
  data?: PremiumFeaturesFragment;
}

export const usePremiumFeaturesFragment = ({
  from,
  returnPartialData,
  ...options
}: UsePremiumFeaturesFragmentOptions): UsePremiumFeaturesFragmentResult => {
  const result = Apollo.useFragment<PremiumFeaturesFragment>({
    ...options,
    fragment: PremiumFeaturesFragmentDoc,
    fragmentName: 'PremiumFeatures',
    from: {
      __typename: 'Board',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as PremiumFeaturesFragment };
};
