import { SharedState } from '@trello/shared-state';

import type {
  ExperimentVariations,
  FeatureExperimentKeys,
  featureExperiments,
  FeatureGateKeys,
} from './data/featureGates';

export interface LocalOverridesSharedState {
  // configs is a general term which includes experiments.
  configs: Partial<{
    // For each experiment (K), get its (K's) parameters as
    // { P: Variation1 | Variation2 | Variation3 }
    [K in FeatureExperimentKeys]: {
      [P in keyof (typeof featureExperiments)[K]['parameters']]?: ExperimentVariations<
        K,
        P
      >;
    };
  }>;
  gates: Partial<Record<FeatureGateKeys, boolean>>;
  // `layers` can be overridden too, but omitted from typing until used.
}

export const localOverridesInitialState: LocalOverridesSharedState = {
  configs: {},
  gates: {},
};

export const localOverridesState = new SharedState<LocalOverridesSharedState>(
  localOverridesInitialState,
);
