/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { forTemplate } from '@trello/legacy-i18n';
import { showFlag } from '@trello/nachos/experimental-flags';

const format = forTemplate('popover_change_labels');

// Mixing-in classes must have a labelList property that the mixin can access.
export const LabelsHelper = {
  // @ts-expect-error TS(7023): 'getLabels' implicitly has return type 'any' becau... Remove this comment to see the full error message
  getLabels() {
    // @ts-expect-error TS(2339): Property 'labelList' does not exist on type '{ get... Remove this comment to see the full error message
    return this.labelList.models;
  },

  // @ts-expect-error TS(7023): 'dataForLabel' implicitly has return type 'any' be... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataForLabel(label: any) {
    return { ...label.toJSON(), isActive: this.hasLabel(label) };
  },

  // @ts-expect-error TS(7023): 'toggleLabelColor' implicitly has return type 'any... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleLabelColor(color: any) {
    // @ts-expect-error TS(2339): Property 'getBoard' does not exist on type '{ getL... Remove this comment to see the full error message
    const label = this.getBoard().labelForColor(color);
    if (label != null) {
      return this.toggleLabel(label);
    }
  },

  // @ts-expect-error TS(7023): 'hasLabel' implicitly has return type 'any' becaus... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hasLabel(label: any) {
    // @ts-expect-error TS(2339): Property 'labelList' does not exist on type '{ get... Remove this comment to see the full error message
    return this.labelList.contains(label);
  },

  // @ts-expect-error TS(7023): 'toggleLabel' implicitly has return type 'any' bec... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleLabel(label: any, toggleOn?: any, next?: any) {
    if (!label) {
      showFlag({
        // @ts-expect-error TS(2322): Type '"toggleLabelFailed"' is not assignable to ty... Remove this comment to see the full error message
        id: 'toggleLabelFailed',
        title: format('something-went-wrong'),
        appearance: 'error',
      });
      return;
    }

    if (toggleOn == null) {
      toggleOn = !this.hasLabel(label);
    }

    // @ts-expect-error TS(2339): Property 'recordAction' does not exist on type '{ ... Remove this comment to see the full error message
    if (typeof this.recordAction === 'function') {
      // @ts-expect-error TS(2339): Property 'recordAction' does not exist on type '{ ... Remove this comment to see the full error message
      this.recordAction({
        type: toggleOn ? 'add-label' : 'remove-label',
        idLabel: label.id,
      });
    }

    // @ts-expect-error TS(2339): Property 'toggle' does not exist on type '{ getLab... Remove this comment to see the full error message
    return this.toggle('idLabels', label.id, toggleOn, next);
  },
};
