import { TrelloSessionStorage, TrelloStorage } from '@trello/storage';

import { SharedState } from './SharedState';

interface PersistentSharedStateOptions {
  storageKey: string | (() => string);
  session?: boolean;
}

/**
 * Represents a shared value (can be observed and updated in a shared context)
 * that's also persisted in a lightweight way (think local/session store).
 *
 * See {@link SharedState}.
 */
export class PersistentSharedState<
  Value extends string | number | object | boolean,
> extends SharedState<Value> {
  #hasSyncLock: boolean = false;

  constructor(
    initialValue: Value,
    { storageKey, session = false }: PersistentSharedStateOptions,
  ) {
    super(initialValue);

    // eslint-disable-next-line @trello/enforce-variable-case
    const Storage = session ? TrelloSessionStorage : TrelloStorage;

    const thisKey =
      typeof storageKey === 'function' ? storageKey() : storageKey;

    const existingValue = Storage.get(thisKey);
    if (existingValue !== null && existingValue !== initialValue) {
      this.setValue(existingValue);
    }

    this.subscribe((value) => {
      if (this.#hasSyncLock) {
        return;
      }

      Storage.set(thisKey, value);
    });

    Storage.listenSyncedAcrossBrowser(({ key, newValue }) => {
      if (key !== thisKey) {
        return;
      }

      const value =
        newValue !== null ? (JSON.parse(newValue) as Value) : initialValue;

      this.#hasSyncLock = true;
      this.setValue(value);
      this.#hasSyncLock = false;
    });
  }
}
