import { useHasValidAaSession } from '@trello/heartbeat/session';
import { TrelloStorage } from '@trello/storage';

const AUTO_OPEN_CFFE_STORAGE_KEY = 'autoOpenCFFE';

export function useAutoOpenCrossFlow() {
  const [hasValidAaSession] = useHasValidAaSession();

  //Auto open Trello crossFlow if user is
  //returning from login to validate AA session
  const shouldAutoOpenCrossFlow =
    TrelloStorage.get(AUTO_OPEN_CFFE_STORAGE_KEY) && hasValidAaSession;

  return {
    wouldRender: shouldAutoOpenCrossFlow || false,
  };
}
