import { Analytics } from '@trello/atlassian-analytics';
import { featureFlagClient } from '@trello/feature-flag-client';
import type { MonitorStatus } from '@trello/monitor';
import { monitor, monitorEvents } from '@trello/monitor';

let sessionStartTime = 0;

// eslint-disable-next-line no-restricted-syntax
enum SessionCompletedReasons {
  TIMED_OUT = 'session timed out',
  HIDDEN = 'page hidden',
}

export const userSession = {
  init() {
    userSession.onActive();
    monitorEvents.on('setStatus', userSession.onStatusChange);
    monitorEvents.on('setStale', userSession.onStale);
    monitorEvents.on('visibilitychange', userSession.onVisibilityChange);
  },

  onStatusChange(newStatus: MonitorStatus) {
    if (newStatus === 'active') {
      userSession.onActive();
    }
  },

  onActive() {
    if (!sessionStartTime) {
      sessionStartTime = Date.now();
    }
  },

  onStale() {
    if (sessionStartTime) {
      userSession.sendSessionCompletedEvent();
      sessionStartTime = 0;
    }
  },

  onVisibilityChange() {
    const isHidden = monitor.getHidden();
    // if we are in an active session and the page is hidden then
    // treat it as if we got a stale event
    if (sessionStartTime && isHidden) {
      userSession.sendSessionCompletedEvent(SessionCompletedReasons.HIDDEN);
      sessionStartTime = 0;
    }
    // if we are not in an active session and the page is visible
    // then treat it as it got an active event
    else if (!sessionStartTime && !isHidden) {
      userSession.onActive();
    }
  },

  sendSessionCompletedEvent(reason = SessionCompletedReasons.TIMED_OUT) {
    // this will only be sent when triggered by the monitorEvents - setStale event
    // but I pulled it into a separate function in case we figure out other times to
    // send this, including with different reasons
    if (featureFlagClient.get('fep.user-session-tracking', false)) {
      let timeSpent;

      // had to split the time calculations up based on reason since
      // a page hidden event always triggers lastActivity and caused
      // us to not really track the true session time in some instances
      if (reason === SessionCompletedReasons.TIMED_OUT) {
        const monLastActivityTime = monitor.getLastActivity();
        timeSpent = monLastActivityTime
          ? monLastActivityTime - sessionStartTime
          : Date.now() - sessionStartTime;
      } else {
        timeSpent = Date.now() - sessionStartTime;
      }

      Analytics.sendOperationalEvent({
        action: 'completed',
        actionSubject: 'userSession',
        source: 'sessionTracking',
        attributes: {
          reason,
          timeSpent,
        },
      });
    }
  },

  getSessionStartTime() {
    return sessionStartTime;
  },

  /***
   * @deprecated Only exposed for testing. Do not use.
   */
  destroy() {
    monitorEvents.off('setStatus', userSession.onStatusChange);
    monitorEvents.off('setStale', userSession.onStale);
    monitorEvents.off('visibilitychange', userSession.onVisibilityChange);
    sessionStartTime = 0;
  },
};
