import { useCallback, useEffect } from 'react';

import { isMemberLoggedIn } from '@trello/authentication';
import { isEmbeddedDocument } from '@trello/browser';
import { useFeatureGate } from '@trello/feature-gate-client';
import { RouteId, useIsActiveRoute } from '@trello/router';
import { useSharedState, useSharedStateSelector } from '@trello/shared-state';
import { workspaceNavigationHiddenState } from '@trello/workspace-navigation';
import { usePreviousWhileLoading, workspaceState } from '@trello/workspaces';

import { workspaceNavigationErrorState } from './workspaceNavigationErrorState';

export const navHiddenRoutes = new Set([
  RouteId.MEMBER_HOME_WORKSPACE_BOARDS,
  RouteId.ORGANIZATION_HIGHLIGHTS,
  RouteId.OLD_ORGANIZATION_HIGHLIGHTS,
  RouteId.ORGANIZATION_REPORTS,
  RouteId.OLD_ORGANIZATION_REPORTS,
  RouteId.ORGANIZATION_GETTING_STARTED,
  RouteId.OLD_ORGANIZATION_GETTING_STARTED,
  RouteId.CREATE_FIRST_BOARD,
  RouteId.CREATE_FIRST_TEAM,
  RouteId.MEMBER_CARDS_FOR_ORG,
  RouteId.OLD_MEMBER_CARDS_FOR_ORG,
]);

export const splitScreenHiddenRoutes = new Set([
  ...navHiddenRoutes,
  RouteId.CARD,
  RouteId.BOARD,
]);

export function useWorkspaceNavigationHiddenStateUpdater() {
  const [navHiddenState, setNavHiddenState] = useSharedState(
    workspaceNavigationHiddenState,
  );
  const { value: isSplitScreenEnabled } = useFeatureGate(
    'phoenix_fg_split_screen_nav',
  );
  const [navErrorState] = useSharedState(workspaceNavigationErrorState);
  const isHiddenRoute = useIsActiveRoute(
    isSplitScreenEnabled ? splitScreenHiddenRoutes : navHiddenRoutes,
  );

  const workspace = useSharedStateSelector(
    workspaceState,
    useCallback(
      (state) => ({
        isGlobal: state.isGlobal,
        isLoading: state.isLoading,
      }),
      [],
    ),
  );

  const isGlobal = usePreviousWhileLoading(
    workspace.isGlobal,
    workspace.isLoading,
    false,
  );

  const hideWorkspaceNav =
    isEmbeddedDocument() ||
    isHiddenRoute ||
    isGlobal ||
    navErrorState.hasError ||
    !isMemberLoggedIn();

  useEffect(() => {
    if (hideWorkspaceNav !== navHiddenState.hidden) {
      setNavHiddenState({ hidden: hideWorkspaceNav });
    }
  }, [hideWorkspaceNav, navHiddenState.hidden, setNavHiddenState]);
}
