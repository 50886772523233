import { isValidElement } from 'react';
import _ from 'underscore';

import { renderReactRoot } from '@trello/component-wrapper';
import { getFeatureGateAsync } from '@trello/feature-gate-client';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import {
  Key,
  registerShortcut,
  Scope,
  unregisterShortcut,
} from '@trello/keybindings';
import {
  ELEVATION_ATTR,
  registerClickOutsideHandler,
  unregisterClickOutsideHandler,
} from '@trello/layer-manager';
import ReactDOM from '@trello/react-dom-wrapper';

import { currentModelManager } from 'app/scripts/controller/currentModelManager';
import { WindowSize } from 'app/scripts/lib/window-size';
import { templates } from 'app/scripts/views/internal/templates';
import { Layout } from 'app/scripts/views/lib/Layout';
import { PluginModal } from 'app/scripts/views/lib/PluginModal';
import { DialogCloseButtonTemplate } from 'app/scripts/views/templates/DialogCloseButtonTemplate';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';

// Modal
class Dialog {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $body: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $content: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $dialog: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $overlay: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _lastArgs: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayType: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dr: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fnOnHide: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hardToClose: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  harderToClose: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isVisible: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastScroll: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScrollInner: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overlayClasses: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scrolltop: any;
  unmountReactRoot: (() => void) | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  view: any;
  isRenderReactRootEnabled: boolean;
  constructor() {
    this.$body = null;
    this.$overlay = null;
    this.$dialog = null;
    this.$content = null;
    this.fnOnHide = null;

    this.onShortcut = this.onShortcut.bind(this);
    this.hide = this.hide.bind(this);
    this.unmountReactRoot = null;
    this.isRenderReactRootEnabled = false;
  }

  onShortcut() {
    this.hide(false, false, false, true);
  }

  init() {
    this.$body = $('body');
    this.$overlay = $('.window-overlay');
    this.$dialog = $('.window');
    this.$content = this.$dialog.find('.window-wrapper');
    this.$content.addClass('js-tab-parent');

    this.scrolltop = 0;
    this.isVisible = false;
    this.displayType = null;
    this.lastScroll = 0;
    this.hardToClose = true;

    // Keeps the dialog from closing by clicking outside of it.
    // See doc-init.js
    this.harderToClose = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$overlay.on('scroll mousedown keydown', (e: any) => {
      return (this.lastScroll = Date.now());
    });

    this.$dialog.on('scroll', () => {
      return typeof this.onScrollInner === 'function'
        ? this.onScrollInner()
        : undefined;
    });

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClickOutside(event: any) {
    // We aren't interested in this event if the dialog is not visible
    if (!this.isVisible) {
      return;
    }

    return this.hide(false, false, false, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scrolledSince(ms: any) {
    return this.lastScroll > ms;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  calcPos(maxWidth?: any) {
    // if the dialog is visible
    // set the left position, position off the page
    // if not visible
    // set the top and left position

    // For small window size, change width to 'auto'
    // For other window sizes, use maxWidth or reset to ''
    let cssWidth;
    if (WindowSize.fSmall) {
      cssWidth = 'auto';
    } else if (maxWidth) {
      cssWidth = maxWidth;
    } else if (this._lastArgs) {
      cssWidth = this._lastArgs.maxWidth;
    }

    this.$dialog.css({
      width: cssWidth || '',
    });

    if (!this.isVisible) {
      this.$dialog.show();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async renderReactComponent(args: any) {
    this.isRenderReactRootEnabled = await getFeatureGateAsync(
      'tplat_fg_migrate_dialog',
    );
    if (this.isRenderReactRootEnabled) {
      this.unmountReactRoot = renderReactRoot(
        args.reactElement,
        this.$content[0],
        true,
      ).unmount;
    } else {
      ReactDOM.render(args.reactElement, this.$content[0]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  show(args: any) {
    this._lastArgs = args;

    // It's possible that we're already showing another dialog, if so we need
    // to hide that one first so it'll let go of its shortcuts
    if (this.isVisible) {
      this.hide(args.isNavigating);
    }

    // hide / clear / reset stuff
    Layout.cancelEdits();
    PluginModal.close();

    this.fnOnHide = args.hide;

    if (args.onScrollInner) {
      this.onScrollInner = args.onScrollInner;
    }

    this.scrolltop = $(window).scrollTop();

    this.$body.addClass('window-up');

    const currentBoard = currentModelManager.getCurrentBoard();
    if (currentBoard) {
      // @ts-expect-error TS(2339): Property 'composer' does not exist on type 'Backbo... Remove this comment to see the full error message
      currentBoard.composer.save('vis', false);
    }

    this.$body
      .find('.js-disable-on-dialog:not([disabled])')
      .addClass('disabled-for-dialog')
      .attr('disabled', 'disabled');

    if (args.hardToClose) {
      this.hardToClose = args.hardToClose;
    }

    // Set harderToClose arg or reset
    this.harderToClose = args.harderToClose || false;

    // special display types before adding to the DOM.
    if (args.displayType) {
      this.$dialog.addClass(args.displayType);
      this.displayType = args.displayType;
      this.overlayClasses = _.chain(args.displayType.split(' '))
        .map((c) => `${c}-overlay`)
        .join(' ')
        .value();
      this.$overlay.addClass(this.overlayClasses);
    }

    if (args.opacity) {
      this.$overlay.css('background-color', `rgba(0, 0, 0, ${args.opacity})`);
    }

    // add the close button
    this.$content.empty().append(templates.fill(DialogCloseButtonTemplate));

    // if we've been given a maxWidth, pass it to calcPos
    if (args.maxWidth) {
      this.calcPos(args.maxWidth);
    } else {
      this.calcPos('');
    }

    // Dialogs don't have a 'trigger' that we can increment our elevation from,
    // they are effectively stand-alone (they might be rendered due to a route for example).
    // Setting a hard coded elevation of '1' ensures any popovers _inside_ the dialog
    // will correctly increment their elevation to '2' in addition to allowing them to work
    // with the click outside handler.
    // We also want to ensure this elevation attribute is set _before_ we render our content,
    // so that any elements inside it can increment their elevation correctly
    this.$content.attr(ELEVATION_ATTR, 1);

    if (args.view) {
      this.view = args.view;

      this.$content.append(this.view.el);
      this.view.render();

      // NOTE: This is tricky.  If we don't explicitly call delegateEvents
      // here, then when jQuery replaced the existing content, it may have
      // removed our previous view.el from the DOM ... and if it did that
      // then all the events attached by the built-in call to delegateEvents
      // will be lost.  We restore them by calling view.delegateEvents() again
      this.view.delegateEvents();
    } else if (args.html) {
      this.view = null;
      this.$content.append(args.html);
    } else if (args.reactElement) {
      if (!isValidElement(args.reactElement)) {
        throw new Error('Dialog args.reactElement has to be a react element');
      }

      this.dr = true;
      this.renderReactComponent(args);
    }

    this.isVisible = true;
    this.lastScroll = 0;

    _.defer(() => {
      this.$dialog.css({
        height: 100000,
      });
      this.$overlay.scrollTop(1);
      this.$overlay.scrollTop(0);
      return this.$dialog.css({
        height: '',
      });
    });
    this.$dialog.find('.js-autofocus:first').focus().select();
    // events
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$dialog.find('input[type=submit]').click(function (ev: any) {
      ev?.preventDefault();
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.$dialog.find('.js-close-window').click((e: any) => {
      if (args.onClose) {
        args.onClose();
      }
      stopPropagationAndPreventDefault(e);
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      this.hide(null, true);
    });

    registerShortcut(this.onShortcut, {
      scope: Scope.Dialog,
      key: Key.Escape,
    });

    registerClickOutsideHandler(this.$content[0], this.handleClickOutside);
  }

  // If isNavigating is true, then we're being hidden
  // because the user is navigating to a new page (not
  // because they clicked outside the dialog or on the
  // close button)
  hide(
    isNavigating = false,
    force = false,
    closePopup = false,
    hideAndNavigate = false,
  ) {
    if (
      (!this.isVisible ||
        Layout.isEditing() ||
        $('.new-comment').hasClass('focus')) &&
      !force &&
      !isNavigating
    ) {
      return;
    }

    this._lastArgs = null;

    if (typeof this.fnOnHide === 'function') {
      this.fnOnHide(isNavigating, hideAndNavigate);
    }
    this.fnOnHide = null;

    // remove special classes
    this.$overlay.removeClass(this.overlayClasses);
    this.$dialog.removeClass(this.displayType);
    this.displayType = null;

    // reset opacity
    this.$overlay.css('background-color', '');

    if (this.view) {
      this.view.remove();
      this.view = null;
    }

    if (this.dr) {
      if (this.isRenderReactRootEnabled && this.unmountReactRoot) {
        this.unmountReactRoot();
      } else {
        ReactDOM.unmountComponentAtNode(this.$content[0]);
      }
      this.unmountReactRoot = null;
      this.dr = null;
    }

    unregisterShortcut(this.onShortcut);
    this.$content.removeAttr(ELEVATION_ATTR);
    unregisterClickOutsideHandler(this.$content[0], this.handleClickOutside);

    this.$dialog.hide();

    this.$body.removeClass('window-up');

    _.defer(() => {
      $(window).scrollTop(this.scrolltop);
      return (this.scrolltop = 0);
    });

    this.isVisible = false;

    $('.disabled-for-dialog').prop('disabled', false);

    this.$body.trigger('dialog-hide', { closePopup });

    this.clear();
  }

  clear() {
    this.$content.width('');
    this.$content.empty();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contains(elem: any) {
    return this.$dialog.find(elem).length > 0;
  }
}

// eslint-disable-next-line @trello/no-module-logic
const dialog = new Dialog();

export { dialog as Dialog };
