import type { FunctionComponent, PropsWithChildren } from 'react';

import ReactDOM from '@trello/react-dom-wrapper';

import type { Layers } from './LayerManagerProvider';

interface LayerManagerPortalProps {
  layer: Layers;
}

export const LayerManagerPortal: FunctionComponent<
  PropsWithChildren<LayerManagerPortalProps>
> = ({ layer, children }) => {
  const portalElement = document.getElementById(layer);

  return portalElement ? ReactDOM.createPortal(children, portalElement) : null;
};
