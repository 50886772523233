import { getRouteIdFromPathname } from './getRouteIdFromPathname';
import type { RouteId, Routes } from './routes';
import { routes } from './routes';

export function getRouteParamsFromPathname<T extends RouteId>(
  pathname: string,
): ReturnType<Routes[T]['getRouteParams']> {
  const routeId = getRouteIdFromPathname(pathname);
  const route = routes[routeId];

  return route.getRouteParams(pathname) as ReturnType<
    Routes[T]['getRouteParams']
  >;
}
