import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { CardCvcElement } from '@stripe/react-stripe-js';
import type { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';

import { forTemplate } from '@trello/legacy-i18n';
import { Button } from '@trello/nachos/button';
import { HelpIcon } from '@trello/nachos/icons/help';
import { Popover, usePopover } from '@trello/nachos/popover';
import type { PIIString } from '@trello/privacy';
import {
  convertToPIIString,
  dangerouslyConvertPrivacyString,
} from '@trello/privacy';
import type { PurchaseFormIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import { useGlobalTheme } from '@trello/theme';

import { getDefaultIframeOptions } from './getDefaultIframeOptions';
import { Label } from './Label';
import { ValidationError } from './ValidationError';

import styles from './ADSCreditCardCvv.less';

// eslint-disable-next-line @trello/assets-alongside-implementation
import creditCardPng from 'resources/images/credit-cards/credit-card.png';
// eslint-disable-next-line @trello/assets-alongside-implementation
import creditCardAmexPng from 'resources/images/credit-cards/credit-card-amex.png';

const format = forTemplate('credit_card');

export interface CreditCardCvvInputProps {
  isInvalid?: boolean;
  isDisabled?: boolean;
  onChange?: (event: StripeCardCvcElementChangeEvent) => void;
  errorMessage?: string | null;
  cardType: PIIString;
}

interface CVVTooltipProps {
  cardType: PIIString;
  niceCardType: PIIString;
}

// eslint-disable-next-line @trello/enforce-variable-case
const CVVTooltip: FunctionComponent<CVVTooltipProps> = ({
  cardType,
  niceCardType,
}) => {
  const niceCardTypeString = dangerouslyConvertPrivacyString(niceCardType);
  const cardTypeString = dangerouslyConvertPrivacyString(cardType);

  const Amex = (
    <div className={styles.cardExample}>
      <h4>{format('amex_title')}</h4>
      <p>{format('amex_info')}</p>
      <img
        src={creditCardAmexPng}
        alt={niceCardTypeString}
        className={styles.preview}
      />
    </div>
  );

  const OtherCards = (
    <div className={styles.cardExample}>
      <h4>{format('other_cards_title')}</h4>
      <p>{format('other_cards_info')}</p>
      <img
        src={creditCardPng}
        alt={niceCardTypeString}
        className={styles.preview}
      />
    </div>
  );

  switch (cardTypeString) {
    case 'amex':
      return Amex;
    case 'visa':
    case 'mastercard':
    case 'discover':
      return OtherCards;
    default:
      return (
        <>
          {Amex}
          {OtherCards}
        </>
      );
  }
};

export const ADSCreditCardCvv: FunctionComponent<CreditCardCvvInputProps> = ({
  isDisabled,
  isInvalid,
  onChange,
  errorMessage,
  cardType,
}) => {
  const { triggerRef, toggle, popoverProps } = usePopover<HTMLButtonElement>();
  const { effectiveColorMode } = useGlobalTheme();
  const iframeOptions = useMemo(
    () => ({
      ...getDefaultIframeOptions({ isDisabled }),
      disabled: isDisabled,
      placeholder: format('cvv digits', {
        digits: cardType === convertToPIIString('amex') ? '4' : '3',
      }),
      theme: effectiveColorMode,
    }),
    [isDisabled, cardType, effectiveColorMode],
  );

  return (
    <div
      data-testid={getTestId<PurchaseFormIds>('credit-card-cvv')}
      className={styles.container}
    >
      <Label
        isRequired
        iconAfter={
          <Button
            className={styles.help}
            ref={triggerRef}
            onClick={toggle}
            appearance="subtle"
            iconBefore={
              <HelpIcon label={format('open-cvv-tooltip')} size="xsmall" />
            }
          />
        }
      >
        {format('cvv')}
      </Label>
      <CardCvcElement options={iframeOptions} onChange={onChange} />
      <Popover {...popoverProps} size="small">
        <CVVTooltip cardType={cardType} niceCardType={cardType} />
      </Popover>
      {isInvalid && errorMessage && (
        <ValidationError
          id={getTestId<PurchaseFormIds>('credit-card-cvv-validation-error')}
        >
          {errorMessage}
        </ValidationError>
      )}
    </div>
  );
};
