import { useEffect, useRef } from 'react';

import type { CustomAttributes } from '@atlaskit/feature-gate-js-client';
import { useFeatureFlag } from '@trello/feature-flag-client';
import { deepEqual } from '@trello/objects';
import { useSharedState } from '@trello/shared-state';
import { workspaceState } from '@trello/workspaces';

import { initFeatureGateClient } from './initFeatureGateClient';
import { initFeatureGateClientWithWrapper } from './initFeatureGateClientWithWrapper';
import { refreshFeatureGatesClient } from './refreshFeatureGatesClient';
import { useFeatureGateClientCustomAttributes } from './useFeatureGateClientCustomAttributes';
import { useFeatureGatesClientRefresher } from './useFeatureGatesClientRefresher';

export const useFeatureGateClientInitializer = () => {
  // eslint-disable-next-line @trello/no-feature-flag -- LD flags are deprecated. See http://go/bye-feature-flags.
  const isStatsigFeatureGatesRefresherEnabled = useFeatureFlag(
    'xf.statsig-feature-gates-refresher_rgroe',
    false,
  );
  const [workspaceStateValue] = useSharedState(workspaceState);
  const customAttributes = useFeatureGateClientCustomAttributes();

  const prevCustomAttributes = useRef<CustomAttributes>({});

  useEffect(() => {
    async function init() {
      if (isStatsigFeatureGatesRefresherEnabled) {
        await initFeatureGateClientWithWrapper({
          customAttributes,
          workspaceId: workspaceStateValue.workspaceId,
        });
      } else {
        await initFeatureGateClient({
          customAttributes,
          workspaceId: workspaceStateValue.workspaceId,
        });
      }
    }
    if (!deepEqual(prevCustomAttributes.current, customAttributes)) {
      prevCustomAttributes.current = customAttributes;
      init();
    }
  }, [
    customAttributes,
    workspaceStateValue.workspaceId,
    isStatsigFeatureGatesRefresherEnabled,
  ]);

  useFeatureGatesClientRefresher(() =>
    refreshFeatureGatesClient({
      customAttributes,
      workspaceId: workspaceStateValue.workspaceId,
    }),
  );
};
