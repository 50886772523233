import { WebSocketState } from './webSocketState';

// This function is implemented in a bit of an odd way. This is to ensure that whenever we add or remove WebSocketState
// values, that this function also needs to be updated. If they don't get added here, we will get type errors.
export function isValidReconnectionState(state: WebSocketState): boolean {
  // eslint-disable-next-line default-case
  switch (state) {
    case WebSocketState.FORCE_DISCONNECTED:
    case WebSocketState.RATE_LIMITED:
    case WebSocketState.TOO_FAR_BEHIND:
    case WebSocketState.RECONNECTIONS_BLOCKED:
    case WebSocketState.CONNECTED:
      return false;
    case WebSocketState.CLOSED:
    case WebSocketState.CONNECTING:
    case WebSocketState.DISCONNECTED:
    case WebSocketState.WAITING_TO_RECONNECT:
      return true;
  }
}
