// eslint-disable-next-line no-restricted-syntax
export enum ListErrorExtensions {
  BOARD_TOO_MANY_CUSTOM_FIELDS = 'BOARD_TOO_MANY_CUSTOM_FIELDS',
  CUSTOM_FIELD_TOO_MANY_OPTIONS = 'CUSTOM_FIELD_TOO_MANY_OPTIONS',
}

export const ListErrors: Record<string, ListErrorExtensions> = {
  'too many custom fields': ListErrorExtensions.BOARD_TOO_MANY_CUSTOM_FIELDS,
  'too many custom field options':
    ListErrorExtensions.CUSTOM_FIELD_TOO_MANY_OPTIONS,
};
