import { useCallback, useRef } from 'react';

/**
 * Returns an invisible element that can be focused to simulate blur on another
 * element within a focus trapping context. The synthetic tab target should be
 * rendered as a direct sibling of the blurred element, so that the user's
 * position in the tab navigation hierarchy remains in the right place.
 *
 * This is a workaround for a niche issue with our focus trapping library,
 * which prevents blurring an element from working at all:
 * https://github.com/adobe/react-spectrum/issues/1479
 *
 * @example
 *
 * const { focusSyntheticTabTarget, SyntheticTabTarget } = useSyntheticTabTarget();
 *
 * // Blur the element when the Enter key is pressed:
 * const onKeyDown = (e) => {
 *   if (isSubmitEvent(e)) {
 *     e.preventDefault();
 *     // Blur the current element by focusing the synthetic tab target:
 *     focusSyntheticTabTarget();
 *   }
 * };
 *
 * return (
 *   <>
 *     <input onKeyDown={onKeyDown} />
 *     <SyntheticTabTarget />
 *   </>
 * );
 */
export const useSyntheticTabTarget = () => {
  const syntheticTabTargetRef = useRef<HTMLDivElement | null>(null);

  const focusSyntheticTabTarget = useCallback(() => {
    syntheticTabTargetRef.current?.focus();
  }, []);

  const SyntheticTabTarget = useCallback(
    () => <span ref={syntheticTabTargetRef} role="none" tabIndex={-1} />,
    [],
  );

  return {
    focusSyntheticTabTarget,
    SyntheticTabTarget,
  };
};
