/* eslint-disable @trello/disallow-filenames */
import type { Card as CardType } from '@trello/model-types';

import type {
  Checklist,
  SingleBoardViewBoard,
  ViewCard,
} from 'app/src/components/BoardViewContext/BoardViewContext';
import type { LabelColor, LabelName } from 'app/src/components/TableView/types';
import type { ActivityFilter } from './filters/ActivityFilter';
import type {
  AutoCollapseListsFilter,
  CompleteFilter,
  DueFilter,
  LabelsFilter,
  MembersFilter,
  ModeFilter,
  TitleFilter,
} from './filters';

export type BoardPlugins = SingleBoardViewBoard['boardPlugins'];
export type Card = ViewCard;
export type CalendarCard = ViewCard;

export type ChecklistItem = Checklist['checkItems'][number];

export type CustomFieldOption = Pick<
  CardType['customFields'][number]['options'][number],
  'id' | 'color' | 'value'
>;

export type CustomField = Pick<
  CardType['customFields'][number],
  'id' | 'type' | 'name'
> & {
  options?: CustomFieldOption[] | null;
};

export interface CustomFieldItem
  extends Omit<
    CardType['customFieldItems'][number],
    '__typename' | 'value' | 'idValue'
  > {
  idValue?: string | null;
  value?: {
    checked?: string | null;
    date?: string | null;
    number?: string | null;
    text?: string | null;
  } | null;
}

export interface LabelType {
  color: LabelColor;
  name: LabelName;
}

// eslint-disable-next-line no-restricted-syntax
export enum FilterMode {
  And = 'and',
  Or = 'or',
}

export interface FilterableCard {
  idMembers: string[];
  labels: LabelType[];
  due: Date | null;
  complete: CompleteFilter;
  words: string[];
  dateLastActivity?: Date | string;
}

export type FilterableCardWithCustomFields = Pick<
  Card,
  | 'dateLastActivity'
  | 'due'
  | 'labels'
  | 'idMembers'
  | 'name'
  | 'dueComplete'
  | 'idShort'
> &
  Partial<Pick<Card, 'customFieldItems'>>;

export interface ViewsFilters {
  members: MembersFilter;
  labels: LabelsFilter;
  due: DueFilter;
  title: TitleFilter;
  mode?: ModeFilter;
  dateLastActivity?: ActivityFilter;
  autoCollapse?: AutoCollapseListsFilter;
}
