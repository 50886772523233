import { SharedState } from '@trello/shared-state';

import { getRouteIdFromPathname } from './getRouteIdFromPathname';
import { getRouteParamsFromPathname } from './getRouteParamsFromPathname';
import type { RouteId, Routes } from './routes';

export interface LocationState {
  hostname: string;
  pathname: string;
  search: string;
  hash: string;
  origin: string;
}

export interface RouterState<T extends RouteId> {
  id: RouteId;
  params: ReturnType<Routes[T]['getRouteParams']>;
  location: LocationState;
}

const { pathname, search, hash, hostname, origin } = window.location;

export const routerState = new SharedState<RouterState<RouteId>>({
  // eslint-disable-next-line @trello/no-module-logic
  id: getRouteIdFromPathname(pathname),
  // eslint-disable-next-line @trello/no-module-logic
  params: getRouteParamsFromPathname<RouteId>(pathname),
  location: {
    hostname,
    pathname,
    search,
    hash,
    origin,
  },
});
