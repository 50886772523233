// eslint-disable-next-line no-restricted-syntax
export enum EnterpriseErrorExtensions {
  ORGANIZATION_JOIN_HANDLER_BULK_JOIN_DISABLED = 'ORGANIZATION_JOIN_HANDLER_BULK_JOIN_DISABLED',
  ORGANIZATION_JOIN_HANDLER_NO_ORGANIZATIONS = 'ORGANIZATION_JOIN_HANDLER_NO_ORGANIZATIONS',
  ORGANIZATION_JOIN_HANDLER_TOO_MANY_ORGANIZATIONS = 'ORGANIZATION_JOIN_HANDLER_TOO_MANY_ORGANIZATIONS',
  ORGANIZATION_JOIN_HANDLER_INELIGIBLE_ORGANIZATION = 'ORGANIZATION_JOIN_HANDLER_INELIGIBLE_ORGANIZATION',
  ORGANIZATION_JOIN_HANDLER_NOT_ALLOWED = 'ORGANIZATION_JOIN_HANDLER_NOT_ALLOWED',
  ORGANIZATION_JOIN_HANDLER_NOT_ENOUGH_LICENSES = 'ORGANIZATION_JOIN_HANDLER_NOT_ENOUGH_LICENSES',
  ORGANIZATION_JOIN_HANDLER_MAX_ORGANIZATIONS_REACHED = 'ORGANIZATION_JOIN_HANDLER_MAX_ORGANIZATIONS_REACHED',
  ORGANIZATION_HAS_PUBLIC_PLUGIN = 'ORGANIZATION_HAS_PUBLIC_PLUGIN',
  ENTERPRISE_ORGANIZATIONS_ORG_CCP_BILLED = 'ENTERPRISE_ORGANIZATIONS_ORG_CCP_BILLED',
}

export const EnterpriseErrors: Record<string, EnterpriseErrorExtensions> = {};
