import * as Types from '@trello/graphql/generated';

import { useQuickLoad } from '@trello/quickload';
import type { TrelloQueryHookOptions, TrelloLazyQueryHookOptions, TrelloSuspenseQueryHookOptions } from '@trello/quickload';
import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const MyAdminRolesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyAdminRoles"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"member"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enterprises"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"saml"},{"kind":"EnumValue","value":"member"},{"kind":"EnumValue","value":"memberUnconfirmed"},{"kind":"EnumValue","value":"owned"}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"idEnterprisesAdmin"}},{"kind":"Field","name":{"kind":"Name","value":"idEnterprisesImplicitAdmin"}},{"kind":"Field","name":{"kind":"Name","value":"idPremOrgsAdmin"}},{"kind":"Field","name":{"kind":"Name","value":"memberType"}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"MyAdminRoles","document":MyAdminRolesDocument}} as const;
export type MyAdminRolesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MyAdminRolesQuery = (
  { __typename: 'Query' }
  & { member?: Types.Maybe<(
    { __typename: 'Member' }
    & Pick<Types.Member, 'id' | 'idEnterprisesAdmin' | 'idEnterprisesImplicitAdmin' | 'idPremOrgsAdmin' | 'memberType'>
    & { enterprises: Array<(
      { __typename: 'Enterprise' }
      & Pick<Types.Enterprise, 'id' | 'displayName' | 'name'>
    )> }
  )> }
);

/**
 * __useMyAdminRolesQuery__
 *
 * To run a query within a React component, call `useMyAdminRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAdminRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAdminRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyAdminRolesQuery(
  baseOptions: TrelloQueryHookOptions<
    MyAdminRolesQuery,
    MyAdminRolesQueryVariables
  > &
    (
      | { variables: MyAdminRolesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: MyAdminRolesDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  const result = Apollo.useQuery<MyAdminRolesQuery, MyAdminRolesQueryVariables>(
    MyAdminRolesDocument,
    options,
  );
  // reconstruct result because modifying the useQuery result actually changes apollo behavior because of memoization
  const trelloResult = { ...result };
  if (isQuickLoading) {
    trelloResult.loading = true;
  }

  return trelloResult;
}
export function useMyAdminRolesLazyQuery(
  baseOptions?: TrelloLazyQueryHookOptions<
    MyAdminRolesQuery,
    MyAdminRolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyAdminRolesQuery, MyAdminRolesQueryVariables>(
    MyAdminRolesDocument,
    options,
  );
}
export function useMyAdminRolesSuspenseQuery(
  baseOptions?: TrelloSuspenseQueryHookOptions<
    MyAdminRolesQuery,
    MyAdminRolesQueryVariables
  >,
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: MyAdminRolesDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  return Apollo.useSuspenseQuery<MyAdminRolesQuery, MyAdminRolesQueryVariables>(
    MyAdminRolesDocument,
    options,
  );
}
export type MyAdminRolesQueryHookResult = ReturnType<
  typeof useMyAdminRolesQuery
>;
export type MyAdminRolesLazyQueryHookResult = ReturnType<
  typeof useMyAdminRolesLazyQuery
>;
export type MyAdminRolesSuspenseQueryHookResult = ReturnType<
  typeof useMyAdminRolesSuspenseQuery
>;
export type MyAdminRolesQueryResult = Apollo.QueryResult<
  MyAdminRolesQuery,
  MyAdminRolesQueryVariables
>;
