import { useEffect, useState } from 'react';

import { environment } from '@trello/config';
import { useFeatureFlag } from '@trello/feature-flag-client';

import { useIsFeatureGateClientInitializeCompleted } from './useIsFeatureGateClientInitializeCompleted';
import { usePageVisibilityListener } from './usePageVisibilityListener';

const BASE_REFRESH_INTERVAL_IN_MS_STG = 5_000; // 5 seconds
const BASE_REFRESH_INTERVAL_IN_MS_PROD = 300_000; // 300 seconds = 5 mins

const INACTIVE_REFRESH_POLLING_FACTOR = 12; // decreased polling rate for hidden tabs

const BASE_REFRESH_INTERVAL_IN_MS =
  environment === 'prod'
    ? BASE_REFRESH_INTERVAL_IN_MS_PROD
    : BASE_REFRESH_INTERVAL_IN_MS_STG;

let intervalId: NodeJS.Timeout | undefined;

export const useFeatureGatesClientRefresher = (
  refresh: () => Promise<void>,
) => {
  // eslint-disable-next-line @trello/no-feature-flag -- LD flags are deprecated. See http://go/bye-feature-flags.
  const isStatsigFeatureGatesRefresherEnabled = useFeatureFlag(
    'xf.statsig-feature-gates-refresher_rgroe',
    false,
  );
  const isFeatureGateClientInitializeCompleted =
    useIsFeatureGateClientInitializeCompleted();

  const [isRefreshEnabled, setIsRefreshEnabled] = useState(
    isFeatureGateClientInitializeCompleted &&
      isStatsigFeatureGatesRefresherEnabled,
  );

  const { isPageVisible } = usePageVisibilityListener();

  const [intervalInMs, setIntervalInMs] = useState(BASE_REFRESH_INTERVAL_IN_MS);

  useEffect(() => {
    setIsRefreshEnabled(
      isFeatureGateClientInitializeCompleted &&
        isStatsigFeatureGatesRefresherEnabled,
    );
  }, [
    isFeatureGateClientInitializeCompleted,
    isStatsigFeatureGatesRefresherEnabled,
  ]);

  useEffect(() => {
    // When the tab is inactive, decrease the polling rate
    if (!isPageVisible) {
      setIntervalInMs(
        BASE_REFRESH_INTERVAL_IN_MS * INACTIVE_REFRESH_POLLING_FACTOR, // 1 hour in prod, 1 min in stg
      );
    } else {
      setIntervalInMs(
        BASE_REFRESH_INTERVAL_IN_MS, // 5 minutes in prod, 5 seconds in stg
      );
    }
  }, [isPageVisible]);

  useEffect(() => {
    clearInterval(intervalId);
    if (!isRefreshEnabled) {
      return;
    }
    intervalId = setInterval(async () => {
      await refresh();
    }, intervalInMs);

    return () => {
      clearInterval(intervalId);
    };
  }, [intervalInMs, isRefreshEnabled, refresh]);
};
