export type SubscriptionError = 'unauthorized' | 'forbidden' | 'not found';

// eslint-disable-next-line no-restricted-syntax
export enum Tags {
  Messages = 'messages',
  Updates = 'updates',
  ClientActions = 'clientActions',
  AllActions = 'allActions',
}

export interface Subscription {
  modelType: 'Board' | 'Member' | 'Organization' | 'Enterprise';
  idModel: string;
  tags: Tags[];
}

export interface MemberSubscription extends Subscription {
  modelType: 'Member';
  tags: (Tags.Messages | Tags.Updates)[];
}

export interface BoardSubscription extends Subscription {
  modelType: 'Board';
  tags: (Tags.ClientActions | Tags.Updates)[];
}

export interface OrganizationSubscription extends Subscription {
  modelType: 'Organization';
  tags: (Tags.AllActions | Tags.Updates)[];
}

export interface EnterpriseSubscription extends Subscription {
  modelType: 'Enterprise';
  tags: (Tags.AllActions | Tags.Updates)[];
}

// From https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
// eslint-disable-next-line no-restricted-syntax
export enum WebSocketReadyState {
  Connecting = 0,
  Open = 1,
  Closing = 2,
  Closed = 3,
}
