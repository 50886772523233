import type {
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from 'react';

export function stopPropagationAndPreventDefault(
  e: Event | ReactMouseEvent | ReactKeyboardEvent | JQuery.TriggeredEvent,
) {
  e?.stopPropagation();
  e?.preventDefault();
}
