// eslint-disable-next-line no-restricted-syntax
export enum InviteRestrictValues {
  ANY = 'any',
  MANAGED = 'managed',
  MANAGED_OR_DOMAIN = 'managed-or-domain',
  DOMAIN = 'domain',
}

// eslint-disable-next-line no-restricted-syntax
export enum BoardInviteRestrictValues {
  ANY = 'any',
  ORG = 'org',
  MANAGED = 'managed',
  ORG_OR_MANAGED = 'org-or-managed',
  LICENSED = 'licensed',
  LICENSED_OR_MANAGED = 'licensed-or-managed',
}
