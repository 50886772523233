import { useEffect, useState } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { SourceType } from '@trello/atlassian-analytics';
import { Analytics } from '@trello/atlassian-analytics';
import { useFeatureFlag } from '@trello/feature-flag-client';

import type {
  ExperimentVariations,
  FeatureExperimentKeys,
  FeatureExperimentParameters,
} from './data/featureGates';
import { useGetExperimentValueWithRefresh } from './useGetExperimentValueWithRefresh';
import { useIsExperimentValueOverrideChanged } from './useIsExperimentValueOverrideChanged';
import { useIsFeatureGateClientInitializeCompleted } from './useIsFeatureGateClientInitializeCompleted';

const notEnrolled = 'not-enrolled';

interface UseGetExperimentValueArgs<K, P> {
  experimentName: K;
  parameter: P;
  fireExposureEvent?: boolean;
  options?: {
    isLayer: boolean;
  };
}

export const useGetExperimentValue = <
  K extends FeatureExperimentKeys,
  P extends FeatureExperimentParameters<K>,
>({
  experimentName,
  parameter,
  fireExposureEvent,
  options = { isLayer: false },
}: UseGetExperimentValueArgs<K, P>): Record<P, ExperimentVariations<K, P>> & {
  loading: boolean;
} => {
  const isFeatureGateClientInitializeCompleted =
    useIsFeatureGateClientInitializeCompleted();

  // eslint-disable-next-line @trello/no-feature-flag -- LD flags are deprecated. See http://go/bye-feature-flags.
  const isFeatureGatesRefresherEnabled = useFeatureFlag(
    'xf.statsig-feature-gates-refresher_rgroe',
    false,
  );

  const [{ loading, value }, setExperimentValue] = useState<{
    loading: boolean;
    value: ExperimentVariations<K, P> | undefined;
  }>({
    loading: true,
    value: undefined,
  });

  const [firedGasV3ExposureEvent, setFiredGasV3ExposureEvent] = useState(false);
  const [firedSSExposureEvent, setFiredSSExposureEvent] = useState(false);

  const isExperimentValueOverrideChanged =
    useIsExperimentValueOverrideChanged(experimentName);

  useEffect(() => {
    if (
      !isFeatureGatesRefresherEnabled &&
      isFeatureGateClientInitializeCompleted &&
      isExperimentValueOverrideChanged
    ) {
      if (
        FeatureGates.checkGate('trello_xf_feature_gate_client_analytics_toggle')
      ) {
        Analytics.sendOperationalEvent({
          action: 'changed',
          actionSubject: 'experimentValueOverride',
          source: '@trello/feature-gate-client',
          attributes: {
            experimentName,
            evaluatedExperimentValue: value,
          },
        });
      }
      // On changes to override, reset all state
      setExperimentValue({
        loading: true,
        value: undefined,
      });
      setFiredGasV3ExposureEvent(false);
      setFiredSSExposureEvent(false);
    }
  }, [
    isFeatureGatesRefresherEnabled,
    isFeatureGateClientInitializeCompleted,
    isExperimentValueOverrideChanged,
    value,
    experimentName,
  ]);

  useEffect(() => {
    if (
      !isFeatureGatesRefresherEnabled &&
      isFeatureGateClientInitializeCompleted
    ) {
      if (
        FeatureGates.checkGate('trello_xf_feature_gate_client_analytics_toggle')
      ) {
        Analytics.sendOperationalEvent({
          action: 'evaluated',
          actionSubject: 'experiment',
          source: '@trello/feature-gate-client',
          attributes: {
            experimentName,
            fireExposureEvent,
            firedSSExposureEvent,
            evaluatedExperimentValue: value,
          },
        });
      }
      if (value === undefined || (fireExposureEvent && !firedSSExposureEvent)) {
        if (fireExposureEvent) {
          setFiredSSExposureEvent(true);
        }

        const evaluatedExperimentValue = options.isLayer
          ? FeatureGates.getLayerValue(
              experimentName,
              parameter as string,
              notEnrolled,
              {
                fireLayerExposure: fireExposureEvent,
              },
            )
          : FeatureGates.getExperimentValue<ExperimentVariations<K, P>>(
              experimentName,
              parameter as string,
              notEnrolled,
              {
                fireExperimentExposure: fireExposureEvent,
              },
            );
        Analytics.setFlagEvaluation(experimentName, evaluatedExperimentValue);
        setExperimentValue({
          loading: false,
          value: evaluatedExperimentValue,
        });
      }

      if (
        value !== undefined &&
        fireExposureEvent &&
        !firedGasV3ExposureEvent
      ) {
        setFiredGasV3ExposureEvent(true);
        // Statsig exposure events are not on GAS,
        // so it's safer to fire a GAS event to avoid any data loss if Statsig is down
        Analytics.sendTrackEvent({
          action: 'exposed',
          actionSubject: 'feature',
          source: 'trello' as SourceType,
          attributes: {
            experimentName,
            value,
            isNonPolling: true,
          },
        });
      }
    }
  }, [
    value,
    experimentName,
    isFeatureGateClientInitializeCompleted,
    fireExposureEvent,
    firedSSExposureEvent,
    firedGasV3ExposureEvent,
    isFeatureGatesRefresherEnabled,
    parameter,
    options.isLayer,
  ]);

  const parameterObjWithRefresh = useGetExperimentValueWithRefresh({
    experimentName,
    parameter,
    fireExposureEvent,
    options,
  });

  if (isFeatureGatesRefresherEnabled) {
    return parameterObjWithRefresh;
  }

  // type casted because parameter is not recognized to be of type P.
  const parameterObj = {
    [parameter as P]: value ?? notEnrolled,
  } as Record<P, ExperimentVariations<K, P>>;

  return {
    ...parameterObj,
    loading,
  };
};
