/* eslint-disable
          eqeqeq,
*/
// Generated by CoffeeScript 1.12.7
/*
  Because of the way this file was decompiled, typescript cannot detect its type
  That is why we use `@ts-ignore` in every place we reference this.
  If you're here to convert it to a class, please make sure to clean all the related `@ts-ignore`s
*/
const slice = [].slice;

import Hearsay from 'hearsay';
import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import Backbone from '@trello/backbone';
import { determinePossibleCardRole } from '@trello/card-roles';
import { getApiError, parseXHRError } from '@trello/error-handling';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { getScreenFromUrl } from '@trello/marketing-screens';

import { ModelCache } from 'app/scripts/db/ModelCache';
import { Util } from 'app/scripts/lib/util';
import type { TrelloModel } from 'app/scripts/models/internal/TrelloModel';

interface CollectionWithHelpers<T> extends Backbone.Collection<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  owner: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceModel: any;
}

class CollectionWithHelpers<
  // @ts-expect-error
  T extends TrelloModel,
> extends Backbone.Collection<T> {
  get modelCache() {
    return ModelCache;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(models?: any, options?: any) {
    super(...arguments);
  }

  destructor() {
    this.stopListening();
    return Util.shred(this);
  }

  create(
    attributes: Parameters<typeof Backbone.Collection.prototype.create>[0],
    _options?: Parameters<typeof Backbone.Collection.prototype.create>[1],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _next?: any,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let arg, i, model, next: any, options;
    (model = arguments[0]),
      (arg =
        3 <= arguments.length
          ? slice.call(arguments, 1, (i = arguments.length - 1))
          : ((i = 1), [])),
      (next = arguments[i++]);
    options = arg[0];
    if (!options && !_.isFunction(next)) {
      options = next;
      next = function () {};
    }
    if (options == null) {
      options = {};
    }
    const lockIndex = ModelCache.lock('Collection Create');
    if (options.headers == null) {
      options.headers = {};
    }
    if (options.traceId) {
      options.headers = {
        ...options.headers,
        ...Analytics.getTaskRequestHeaders(options.traceId),
      };
    }
    const ref = options.success;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options.success = (...args: any) => {
      if (ref != null) {
        ref(...args);
      }
      return next(null, args[0]);
    };
    const customError = options.error;
    options.error = (function (_this) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
      return function (model: any, xhr: any, options: any) {
        const textStatus = xhr != null ? xhr.responseText : void 0;
        const status = xhr != null ? xhr.status : void 0;
        if (customError != null) {
          customError(status, textStatus);
        } else {
          Analytics.sendOperationalEvent({
            action: 'errored',
            actionSubject: 'hiddenServerError',
            source: getScreenFromUrl(),
            attributes: {
              textStatus,
            },
          });
        }
        return next(textStatus);
      };
    })(this);
    options.complete = () => ModelCache.unlock(lockIndex);
    if (options.modelCache == null) {
      options.modelCache = ModelCache;
    }
    if (this.model.prototype.typeName === 'Card') {
      let cardRole = determinePossibleCardRole({
        name: model.name,
        description: model.desc,
        numAttachments: 0,
        numLabels: 0,
        numMembers: 0,
        numChecklistItems: 0,
        numCustomFieldItems: 0,
        startDate: model.start,
        dueDate: model.due,
        cover: model.cover,
      });

      if (cardRole === 'mirror') {
        cardRole = null;
      }

      model.cardRole = cardRole;
    }
    return super.create.call(this, model, options);
  }

  // Duplicating this.create with tracing instrumentation, eventually
  // we could move over to this method for all collections
  createWithTracing(
    attributes: Parameters<typeof Backbone.Collection.prototype.create>[0],
    _options?: Parameters<typeof Backbone.Collection.prototype.create>[1] & {
      traceId: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _next?: any,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let arg, i, model, next: any, options;
    (model = arguments[0]),
      (arg =
        3 <= arguments.length
          ? slice.call(arguments, 1, (i = arguments.length - 1))
          : ((i = 1), [])),
      (next = arguments[i++]);
    options = arg[0];
    if (!options && !_.isFunction(next)) {
      options = next;
      next = function () {};
    }
    if (options == null) {
      options = {};
    }
    const lockIndex = ModelCache.lock('Collection Create');
    if (options.headers == null) {
      options.headers = {};
    }

    const traceId = options.traceId;
    if (traceId) {
      options.headers = {
        ...options.headers,
        ...Analytics.getTaskRequestHeaders(traceId),
      };
      delete options.traceId;
    }

    const customSuccess = options.success;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
    options.success = (model: any, xhrResponse: any, xhrOptions: any) => {
      const trelloServerVersion =
        xhrOptions?.xhr?.getResponseHeader('X-Trello-Version');
      Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

      if (customSuccess) {
        customSuccess(model, xhrResponse, xhrOptions);
      }
      // order of arguments for "next" is different from what Backbone gives us, but this is to better reflect node-like callback systems
      return next(null, model);
    };

    const customError = options.error;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
    options.error = (model: any, xhrResponse: any, xhrOptions: any) => {
      const status = xhrResponse != null ? xhrResponse.status : undefined;

      const errorMessage = parseXHRError(xhrResponse);
      // @ts-expect-error
      const error = getApiError(status, errorMessage);
      // @ts-expect-error
      sendNetworkErrorEvent({ status, response: error.toString() });
      error.serverMessage = xhrResponse.responseJSON?.error;

      const trelloServerVersion =
        xhrOptions?.xhr?.getResponseHeader('X-Trello-Version');
      Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

      if (customError) {
        customError(model, error, xhrResponse);
      }

      Analytics.sendOperationalEvent({
        action: 'errored',
        actionSubject: 'hiddenServerError',
        source: getScreenFromUrl(),
        attributes: {
          errorMessage,
          status,
          error,
        },
      });
      // order of arguments for "next" is different from what Backbone gives us, but this is to better reflect node-like callback systems
      return next(error, model);
    };

    options.complete = ModelCache.unlock(lockIndex);
    if (options.modelCache == null) {
      options.modelCache = ModelCache;
    }
    if (this.model.prototype.typeName === 'Card') {
      let cardRole = determinePossibleCardRole({
        name: model.name,
        description: model.desc,
        numAttachments: 0,
        numLabels: 0,
        numMembers: 0,
        numChecklistItems: 0,
        numCustomFieldItems: 0,
        startDate: model.start,
        dueDate: model.due,
        cover: model.cover,
      });

      if (cardRole === 'mirror') {
        cardRole = null;
      }

      model.cardRole = cardRole;
    }

    return super.create.call(this, model, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _update(newModels: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let oldIds: any;
    const shouldReset =
      newModels.length === this.models.length
        ? ((oldIds = {}),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.models.forEach(function (m: any) {
            return (oldIds[m.cid] = true);
          }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          newModels.some(function (m: any) {
            return !oldIds[m.cid];
          }))
        : true;
    if (shouldReset) {
      return this.reset(newModels);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOwner(owner: any) {
    this.owner = owner;
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  syncModel(sourceModel: any, attr: any, arg?: any) {
    let ids = {};
    this.sourceModel = sourceModel;
    const fxGetIds = (arg != null ? arg : {}).fxGetIds;
    const getIds = (function (_this) {
      return function () {
        let ref, ref1;
        const attrValue = _this.sourceModel.get(attr);
        return (ref =
          (ref1 =
            typeof fxGetIds === 'function' ? fxGetIds(attrValue) : void 0) !=
          null
            ? ref1
            : attrValue) != null
          ? ref
          : [];
      };
    })(this);
    const update = (function (_this) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return function (collectionIds: any) {
        const models = _.chain(collectionIds)
          .map(function (id) {
            // @ts-expect-error
            return ModelCache.get(_this.model, id);
          })
          .compact()
          .value();
        return _this._update(models);
      };
    })(this);
    // @ts-expect-error
    this.sync = (function (_this) {
      return function () {
        let i, id, len;
        const newIds = getIds();
        ids = {};
        for (i = 0, len = newIds.length; i < len; i++) {
          id = newIds[i];
          // @ts-expect-error
          ids[id] = true;
        }
        return update(newIds);
      };
    })(this);
    this.listenTo(this.sourceModel, 'change:' + attr, this.sync);
    this.listenTo(
      ModelCache,
      'add:' + this.model.prototype.typeName,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function (model: any) {
        // @ts-expect-error
        if (ids[model.id]) {
          // @ts-expect-error
          this.add(model);
        }
      },
    );
    this.listenTo(
      ModelCache,
      'remove:' + this.model.prototype.typeName,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function (model: any) {
        // @ts-expect-error
        if (ids[model.id]) {
          // @ts-expect-error
          this.remove(model);
        }
      },
    );
    this.sync();
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  syncCache(modelCache: any, watchedAttrs: any, pred: any) {
    let attr, i, len;
    // @ts-expect-error
    this.sync = (function (_this) {
      return function () {
        // @ts-expect-error
        return _this._update(ModelCache.all(_this.model).filter(pred));
      };
    })(this);
    const typeName = this.model.prototype.typeName;
    this.listenTo(
      ModelCache,
      'add:' + typeName,
      (function (_this) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return function (model: any) {
          if (pred(model)) {
            return _this.add(model);
          }
        };
      })(this),
    );
    this.listenTo(
      ModelCache,
      'remove:' + typeName,
      (function (_this) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return function (model: any) {
          if (pred(model)) {
            return _this.remove(model);
          }
        };
      })(this),
    );
    this.listenTo(ModelCache, 'reset:' + typeName, this.sync);
    for (i = 0, len = watchedAttrs.length; i < len; i++) {
      attr = watchedAttrs[i];
      this.listenTo(
        ModelCache,
        'change:' + typeName + ':' + attr,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function (model: any) {
          if (pred(model)) {
            // @ts-expect-error
            return this.add(model);
          } else {
            // @ts-expect-error
            return this.remove(model);
          }
        },
      );
    }
    this.sync();
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  syncSubModels(sourceModel: any, attr: any, discardUnknown?: any) {
    this.sourceModel = sourceModel;
    // @ts-expect-error
    this.sync = (function (_this) {
      return function () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let i, json: any, len, model, ref, ref1, subModel;
        // eslint-disable-next-line @trello/enforce-variable-case
        const subModelsJSON =
          (ref = _this.sourceModel.get(attr)) != null ? ref : [];
        const newModels = function () {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          let i, len;
          const results = [];
          for (i = 0, len = subModelsJSON.length; i < len; i++) {
            json = subModelsJSON[i];
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            subModel = _.find(this.models, function (m: any) {
              return m.id === json.id;
            });
            if (subModel != null) {
              ModelCache.enqueueDelta(subModel, json);
            } else {
              // @ts-expect-error
              subModel = new this.model(json, {
                modelCache: ModelCache,
                // @ts-expect-error
                collection: this,
              });
            }
            results.push(subModel);
          }
          return results;
        }.call(_this);
        if (!discardUnknown) {
          ref1 = _this.models;
          for (i = 0, len = ref1.length; i < len; i++) {
            model = ref1[i];
            if (model.id == null) {
              newModels.push(model);
            }
          }
        }
        return _this._update(newModels);
      };
    })(this);
    this.listenTo(this.sourceModel, 'change:' + attr, this.sync);
    this.sync();
    return this;
  }

  snoop() {
    const attrs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    const events = ['add', 'remove', 'reset']
      .concat(
        attrs.map(function (attr) {
          return ['change', attr].join(':');
        }),
      )
      .join(' ');
    return new Hearsay.ContinuousSignal(
      (function (_this) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return function (send: any) {
          send(_this);
          const update = function () {
            return send(_this);
          };
          _this.listenTo(_this, events, update);
          return function () {
            return _this.stopListening(_this, events, update);
          };
        };
      })(this),
    );
  }
}

export { CollectionWithHelpers };
