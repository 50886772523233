import { useMemo } from 'react';

import { isEmbeddedDocument } from '@trello/browser';
import { RouteId, useIsActiveRoute } from '@trello/router';

const HEADER_ROUTES_BLOCKLIST = new Set<RouteId>([
  RouteId.CREATE_FIRST_BOARD,
  RouteId.CREATE_FIRST_TEAM,
  RouteId.REDEEM,
]);

const BOARD_OR_CARD_ROUTES = new Set<RouteId>([RouteId.BOARD, RouteId.CARD]);

/** The Header should be hidden on certain routes. */
export const useShouldRenderHeader = () => {
  const isRouteThatHidesHeader = useIsActiveRoute(HEADER_ROUTES_BLOCKLIST);
  const isBoardOrCardRoute = useIsActiveRoute(BOARD_OR_CARD_ROUTES);
  const isEmbeddedOnBoard = useMemo(
    () => isEmbeddedDocument() && isBoardOrCardRoute,
    [isBoardOrCardRoute],
  );

  return !isRouteThatHidesHeader && !isEmbeddedOnBoard;
};
