import { useCallback, useEffect } from 'react';

import { Analytics } from '@trello/atlassian-analytics';
import { useSharedStateSelector } from '@trello/shared-state';
import { workspaceState } from '@trello/workspaces';

import { getDefaultAnalyticsContext } from 'app/src/defaultAnalyticsContext';
import { useMemberContextData } from './useMemberContextData';
import { useOrgContextData } from './useOrgContextData';

export const useAnalyticsContext = () => {
  const workspaceId = useSharedStateSelector(
    workspaceState,
    useCallback((state) => state.workspaceId, []),
  );
  const memberData = useMemberContextData();
  const orgData = useOrgContextData(workspaceId);

  useEffect(() => {
    Analytics.clearContext('organization');
    Analytics.clearContext('workspace');
  }, [workspaceId]);

  useEffect(() => {
    const newContext = getDefaultAnalyticsContext({
      member: memberData?.member,
      organization: orgData?.organization,
      workspace: orgData?.organization, //we should use orgData until a workspace exists to query with GraphQL
    });
    Analytics.setContext(newContext);
    // eslint-disable-next-line @trello/exhaustive-deps
  }, [memberData?.member?.id, orgData?.organization?.id]);
};
