import { TrelloStorage } from '@trello/storage';

import type { RegisteredFeatureFlagKey } from './data/featureFlags';

/**
 * Types of feature flag values we support.
 */
declare type PrimitiveSupportedFlagTypes = boolean | string | number;
export declare type SupportedFlagTypes =
  | PrimitiveSupportedFlagTypes
  | Array<PrimitiveSupportedFlagTypes>
  | Partial<{
      [key in RegisteredFeatureFlagKey]: PrimitiveSupportedFlagTypes;
    }>;

type FlagSet = Partial<{
  [key in RegisteredFeatureFlagKey]: SupportedFlagTypes;
}>;

interface RecordedFeatureFlags {
  remote: FlagSet;
  overrides: FlagSet;
}

/**
 * Reading recorded feature flag
 *
 * USE WITH CAUTION!!! Prefer non-falsy values.
 *
 * We are not recording 'falsy' values in snapshot (false, 0, null, '', etc). So there is a chance of:
 * * `override[key] == false` will not be picked if `remote[key] == true`
 * * if `remote[key] == false` and there is no override, **default** value will be served
 *
 * @param key
 * @param defaultValue
 * @returns
 */
export function getRecordedFeatureFlag(
  key: RegisteredFeatureFlagKey,
  defaultValue: SupportedFlagTypes,
): SupportedFlagTypes {
  const FEATURE_FLAGS_KEY = 'featureFlagSnapshot';
  const snapshot = TrelloStorage.get(FEATURE_FLAGS_KEY);
  const { remote = {}, overrides = {} }: RecordedFeatureFlags = snapshot ?? {};
  const keyOverride = overrides[key];
  const keyRemote = remote[key];
  return keyOverride ?? keyRemote ?? defaultValue;
}
